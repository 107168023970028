/* ==============================================================
   CMS Template "SOLR QUERY"
   style declarations for cms_template_06_solrquery.xhtml
   ==============================================================*/
/* SOLR QUERY */
/* BLOCK */
.tpl-solr-query {
    /* ELEMENT */
    &__content {
        margin-bottom: 15px;
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}