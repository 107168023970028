/* ==============================================================
   Widget Metadata
   style declarations for widget_metadata.xhtml
   ==============================================================*/
#widgetMetadata {
    h3 {}
    dl {
        dt {}
        dd {
            margin-bottom: 0.4375rem;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}