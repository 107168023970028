/* ==============================================================
   View Reading Mode
   style declarations for viewReadingMode.xhtml 
   ==============================================================*/
/* READING MODE */
#readingMode {
    margin: 0;
    padding: 0;
    background-color: @readingModeBg; 
}

#readingModeImageWrapper {}

#readingModeImage {
	position: relative;
	.image-fragment {
	    	background-color: rgba(255,255,255,0.3);
	        border: solid @blue 3px;	        	
	    }
}

/* BLOCK */
.reading-mode {
    position: relative;
    height: 100%;
    /* ELEMENT */
    /* NAVIGATION */
    &__navigation {
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        align-content: center;
        height: 70px;
        background-color: @middlegray;
    }
    &__nav {
        &.logo {
        	padding-left: 15px;
        	a {
        		display: flex;
        		align-items: center;
        		font-size: 14px;
        		color: @black;
        		&:hover,
        		&:active,
        		&:focus {
        			color: @black;
        			text-decoration: none;
        		}
        	}
        	img {
        		display: inline-block;
        		max-width: 50px;
        		max-height: 70px;
        		margin-right: 10px;
        	}
        }
        &.controls {
        	[data-toggle="mobile-image-controls"] {
        		display: none;
        		width: 100%;
        		padding: 0 20px;
        		font-size: 21px;
        		text-align: right;
        		color: @gray;
        	}
	    	.image-controls {
	    		padding: 0;
		    	&__options {
		    		float: right;
		    		margin-left: 75px;
		    	}
		    	&__option {
		    		width: 21px;
					height: 32px;
					margin: 0 3px;
		    		a,
		    		button {
		    			display: block;
						height: 30px;
						line-height: 30px;
						padding: 0;
						text-align: center;
						color: @gray;
						&:hover,
						&:active,
						&:focus {
							text-decoration: none;
							color: @gray;
						}
		    		}
		    		&.add-to-bookshelf {
		    			.mx-hidden;
		    		}
		    	}
		    	&__actions {
		    		float: right;
		    	}
		    	&__action {}
	    	}        	
        }
        &.back {
        	padding-right: 15px;
        	text-align: right;
        	a {
        		display: inline-flex;
        		justify-content: flex-end;
        		align-items: center;
        		font-size: 24px;
        		color: @gray;
        		&:hover,
        		&:active,
        		&:focus {
        			color: @gray;
        			text-decoration: none;
        			outline: none;
        		}
        		.fa {
        			position: relative;
        			top: 2px;
        		}
        	}
        }    	
    }
    /* CONTENT */
    &__content {}
    &__content-view,
    &__content-sidebar {
        float: left;
    }
    &__content-view {
        transition: all .3s linear;
        width: 100%;
        &.in {
            width: 70%;
        }
    }
    &__content-view-image {}
    &__content-sidebar {
        position: relative;
        width: 0;
        background-color: @middlegray;
        transition: all .3s linear;
        &.in {
            width: 30%;
            .reading-mode__content-sidebar-inner {
                padding: 15px;
            }
        }
    }
    &__content-sidebar-toggle {
        position: absolute;
        left: -32px;
        margin-top: -32px;
        width: 32px;
        height: 64px;
        line-height: 64px;
        text-align: center;
        background-color: @middlegray;
        button {
            width: 100%;
            height: 64px;
            padding-left: 5px;
            .fa {
                margin-left: -6px;
            }
            .fa-chevron-right {
                display: none;
            }
            .fa-chevron-left {
                display: inline-block;
            }
            &.in {
                .fa-chevron-right {
                    display: inline-block;
                }
                .fa-chevron-left {
                    display: none;
                }
            }
        }
    }
    &__content-sidebar-inner {
        overflow-y: auto;
    }
}

/* MODIFICATION */

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: 1430px) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 3fr 1fr;
	    }
	    &__nav {
	    	&.controls {
	    		.image-controls {
	    			&__options {
	    				margin-left: 0;
	    			}
	    			&__actions {
	    				float: left;
	    			}
	    		}
	    	}
	    }
	}
}
@media screen and (max-width: @largeBreakpoint) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 4fr 1fr;
	    }
	}
} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: 1070px) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 5fr 1fr;
	    }
		&__nav {
			&.logo {
				span {
					.mx-hidden;
				}
			}
		}
	}
}
@media screen and (max-width: @mediumBreakpoint) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 6fr 1fr;
	    }
	}
}
@media screen and (max-width: 950px) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 7fr 1fr;
	    }
	}
}
@media screen and (max-width: 918px) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 8fr 1fr;
	    }
	}
}
@media screen and (max-width: 893px) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 9fr 1fr;
	    }
	}
}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {
	.reading-mode {
		&__navigation {
	        grid-template-columns: 1fr 10fr 1fr;
	    }
	    &__nav {
	    	&.controls {
	    		.image-controls {
	    			&__action {
	    				&.select {
	    					width: 64px;
	    				}
	    				&.zoom-slider {
	    					.mx-hidden;
	    				}
	    			}
	    		}
	    	}
	    }
	    &__content-view {
	    	&.in {
		        width: 60%;	    		
	    	}
	    }
	    &__content-sidebar {
	    	&.in {
		        width: 40%;	    		
	    	} 
	    }
    }
}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.reading-mode {
		&__navigation {
			height: 50px;
		}
		&__nav {
	    	&.controls {
	    		[data-toggle="mobile-image-controls"] {
	    			display: inline-block;
	    		}
	    		.image-controls {
	    			display: none;
	    			position: absolute;
	    			top: 100%;
	    			right: 0;
	    			left: 0;
	    			padding: 15px;
	    			border-bottom: 2px solid @blue;
	    			background-color: @white;
	    			z-index: 999;
	    			&__actions {
	    				ul {
	    					justify-content: flex-start;
	    				}
	    			}
	    			&__action {
	    				margin: 0 3px 5px 3px;
	    			}
	    		}
	    	}
	    }
	    &__content-view {
	    	&.in {
		        width: 10%;	    		
	    	}
	    }
	    &__content-sidebar {
	    	&.in {
		        width: 90%;	    		
	    	} 
	    }
    }
}