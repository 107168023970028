/* ==============================================================
   Admin Licence Type
   style declarations for adminLicenseType.xhtml
   ==============================================================*/
#adminLicenseType {
    h2 {}    
}

#adminlicenseTypeWrapper {
    padding: 10px;
    border: 1px solid @middlegray;
    fieldset {
        h3 {}
        label {}
        input {}
        input[type="text"] {}
        input[type="checkbox"] {}
        textarea {
            resize: vertical;
        }
    }
    .btn {
        margin: 0 0 0 5px;
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}