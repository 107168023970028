/* ==============================================================
   View Object
   style declarations for viewObject.xhtml 
   ==============================================================*/
/* VIEW OBJECT */
/* BLOCK */
.view-object {
	/* ELEMENT */
	/* IMAGE */
	&__image {
		position: relative;
	    border: 1px solid @lightgray;
	    overflow: hidden;
	}
	&__image-loader {}
	&__image-map {
		position: relative;
	    width: 100%;
	    height: 600px;
	    span {
	        display: inline-block;
	        vertical-align: middle;
	        line-height: normal;
	        font-size: 1.5rem;
	    }
	    img {
	        max-width: none;
	    }
	    .ugcBox {
	        &.focus {
	            border: 2px solid red;
	        }
	        &.drawing {
	            border: 2px solid blue;   
	        }
	    }
	    .image-fragment {
	    	background-color: rgba(255,255,255,0.3);
	        border: solid @blue 3px;	        	
	    }
	}
	&__access-denied {
		text-align: center;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
	/* IMAGE MOBILE */
	&__image-mobile {
		.mx-hidden;
	}
	/* VIDEO, AUDIO, TEXT */
	&__video,
	&__audio,
	&__text {
		text-align: center;
	}
	/* VIDEO */
	&__video {}
	/* AUDIO */
	&__audio {}
	/* TEXT */
	&__text {}
}

.tooltipp {
	position: absolute;
	background: rgba(255,255,255,1.0);
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {
	.view-object {
		/* ELEMENT */
		&__image {
			.mx-hidden;
		}
		&__image-mobile {
			.mx-visible;
		}
	}
}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}