/* ==============================================================
   Privacy
   style declarations for privacy.xhtml
   ==============================================================*/
/* PRIVACY */
/* BLOCK */
.view-privacy {
	/* ELEMENT */
	iframe {
		width: 100%;
		height: 165px;
		border: none;
    	border-top: 1px solid @middlegray;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}