/* ==============================================================
   View Overview
   style declarations for viewOverview.xhtml 
   ==============================================================*/
/* VIEW OVERVIEW */
/* BLOCK */
.overview {
	margin-bottom: 15px;
	/* ELEMENT */
	h2 {}
	/* DELETE */
	&__delete {
		margin-bottom: 15px;
		.btn {
			margin: 0;
		}
	}
	/* METADATA */
	&__image {}
	&__metadata {
		.dl-horizontal {
			margin-bottom: 2px;
			&.edit-metadata:hover {
				background-color: @lightgray;
			}
		}
		dt {
			text-align: left;
		}
		dd {
		    text-overflow: ellipsis;
		    overflow: hidden;
		}
	}
	&__metadata-label,
	&__metadata-value,
	&__metadata-delete {
		padding: 5px;
	}
	&__metadata-label,
	&__metadata-value {}
	&__metadata-label {
	   display: block;
       text-overflow: ellipsis;
       overflow: hidden;
	}
	&__metadata-value {
	   display: inline-block;
	}
	&__metadata-delete {}
	&__metadata-select {}
	&__metadata-add {
		.btn {
			margin: 0;
		}
	}
	&__metadata-save {
		padding: 10px 0;
		.btn {
			margin: 0;
		}
	}
	/* DESCRIPTION */
	&__description {
		margin-bottom: 15px;
	}
	&__description-title,
	&__description-action {
		margin-bottom: 10px;
	}
	&__description-title {
		h3 {
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__description-action {
		.btn {
			margin: 0 0 0 5px;
		}
	}
	&__description-action-fullscreen {
	   &.in {
	       position: fixed;
	       top: 3px;
	       right: 10px;
	       z-index: 1000;
	   }
	}
	&__description-text {}
	&__description-editor {}
	/* PUBLICATION */
	&__publication {
		margin-bottom: 15px;
	}
	&__publication-title,
	&__publication-action {
		margin-bottom: 10px;
	}
	&__publication-title {
		h3 {
			margin: 0;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__publication-action {
		.btn {
			margin: 0 0 0 5px;
		}
	}
	&__publication-action-fullscreen {
       &.in {
           position: fixed;
           top: 3px;
           right: 10px;
           z-index: 1000;
       }
    }
	&__publication-navigate {
		margin-bottom: 10px;
		li {
			display: inline-block;
            min-width: 32px;
            height: 32px;
            line-height: 32px;
        }
        a, span {
        	display: block;
            padding: 0 5px;
            text-align: center;
            border: 1px solid @middlegray;
        }
	}
	&__publication-navigate-count {
		span {
			border: none;
		}
	}
	&__publication-text {}
	&__publication-editor {}
	/* HISTORY */
	&__history {
		margin-bottom: 15px;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}