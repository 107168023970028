/* ==============================================================
   Widget Bookshelf List
   style declarations for widget_bookshelfList.xhtml
   ==============================================================*/
/* WIDGET BOOKSHELF LIST */
/* BLOCK */
.widget-bookshelf-list {
	h3 {}
	ul {
		margin-bottom: 15px;
	}
	li {}
	a {}
	/* BACK */
	&__back {
		padding-top: 10px;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}