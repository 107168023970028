/* ==============================================================
   Widget Search Sorting
   style declarations for widget_searchSorting.xhtml
   ==============================================================*/
#widgetSearchSorting {
    ul {
        li {
            a {
                img {
                    float: right;
                    margin: 0.25rem 0 0;
                }
            }
        }
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}