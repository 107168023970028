/* ==============================================================
   Navigation
   style declarations for navigation.xhtml
   ==============================================================*/
/* NAVIGATION */
/* BLOCK */
.navigation {
    position: relative;
    &:before, &:after {
        display: table;
        content: " ";
    }
    &:after {
        clear: both;
    }
    ul {
        float: left;
        &:before, &:after {
            display: table;
            content: " ";
        }
        &:after {
            clear: both;
        }
    }
    li {
        display: block;
        position: relative;
        float: left;
        &.active > a,
        &.active > button {
            background-color: @lightgray;
        }
    }
    a, button {
	    position: relative;
        display: flex;
	    align-items: center;
	    justify-content: space-between;
	    border: none;
        &:hover, 
        &:focus, 
        &:active {
            text-decoration: none;
            background-color: @lightgray;
            outline: none !important;
        }
    }
    .caret {
    	margin-left: 5px;
        color: @middlegray;
    }
    &__user-login {}
    /* SUB MENU */
    &__submenu-trigger {}
    &__submenu {
        display: none;
        position: absolute;
        float: left;
        top: calc(~"100% + 1px");
        left: 0;
        min-width: 250px;
        border: 1px solid @lightgray;
        background-color: @white;
        z-index: 999;
        ul {
            float: none;
        }
        li {
            display: block;
            width: 100%;
            &.divider {
                height: 1px;
                margin: 9px 0;
                overflow: hidden;
                background-color: @middlegray;
            }
        }
        a, button {
        	width: 100%;
        }
        &.in {
            display: block;
        }
        &.level-2, 
        &.level-3, 
        &.level-4, 
        &.level-5 {
            top: 0;
            left: 100%;
            &.left {
            	left: -100%;
            }
        }
    }
    /* MEGA MENU */
    &__megamenu-trigger {
        position: inherit !important;
    }
    &__megamenu-wrapper {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        padding: 15px;
        border: 1px solid @lightgray;
        background: @white;
        z-index: 998;
        &.in {
            display: block;
        }
    }
    &__megamenu-close {
        position: absolute !important;
        top: 0;
        right: 0;
        padding: 10px 15px !important;
        z-index: 999;
        .fa {
            cursor: pointer;
        }
    }
    &__megamenu-element {
        position: relative;
        &:before, &:after {
            display: table;
            content: " ";
        }
        &:after {
            clear: both;
        }
        ul {
            float: none;
        }
        &.level-1 {}
        &.level-2 {
            margin-bottom: 15px;
        }
    }
    &__megamenu-heading {
        float: left;
        width: 25%;
        padding: 0 15px;
        font-weight: bold;
    }
    &__megamenu-item {
        width: 100%;
        padding: 0;
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {
	.navigation { display: none; }
}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}
