/* ==============================================================
   CMS Template "Glossary"
   style declarations for cms_template_20_glossary.xhtml
   ==============================================================*/
/* TPL GLOSSARY */
/* BLOCK */
.tpl-glossary {
    h1 {}
    /* ELEMENT */
    &__text {
    	margin-bottom: 20px;
    }
    &__glossary {
    	padding: 20px;
    	border: 1px solid @middlegray;
    	background-color: @lightgray;
    	hr {
    		border-top-color: @middlegray;
    	}
    }
    &__glossary-title {
    	font-size: 1.25rem;
    	font-weight: 700;
    }
    &__glossary-description {
    	margin-bottom: 15px;
    	p {
    		&:last-child {
    			margin: 0;
    		}
    	}
    }
    &__glossary-keywords,
    &__glossary-source {
    	.fa, strong {
    		margin-right: 3px;
    	}
    }
    &__glossary-keywords {}
    &__glossary-source {}
	/* MODIFICATION */
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}