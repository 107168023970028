/* ==============================================================
   Widget RSS
   style declarations for rssFeed.xhtml
   ==============================================================*/
.rss_elem_wrapp {
    h3 {
        a {
            float: right;
            .fa {
	    		width: 16px;
	    		height: 16px;
	    		line-height: 16px;
	    		font-size: 14px;
	    		text-align: center;
	    		color: @white;
	    		background-color: @orange;
	    	}
        }
    }
    .rss-wrapp {
        .rss-elem {
            dl {
                .rss-elem-title {
                    a {
                        &:hover {}
                    }
                }
                .rss-elem-info {
                    margin-bottom: 0.875rem;
                }
            }
            &:last-child {
            	dl {
	                .rss-elem-title {
	                    a {
	                        &:hover {}
	                    }
	                }
	                .rss-elem-info {
	                    margin: 0;
	                }
	            }	
            }
        }
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}