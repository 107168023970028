/* ==============================================================
   CMS Template "Collection Tiled"
   style declarations for cms_template_09_collectionTiled.xhtml
   ==============================================================*/
/* COLLECTION TILED */
/* BLOCK */
.tpl-collection-tiled {
    /* ELEMENT */
    &__text,
    &__wrapper {
        margin-bottom: 15px;
    }
    &__text {}
    &__wrapper {}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}