/* ==============================================================
   Widget CMS Page Links
   style declarations for widget_cmsPageLinks.xhtml
   ==============================================================*/
/* CMS PAGE LINKS */
/* BLOCK */
[id*="widgetCmsPageLinks"] {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 10px;
		bottom: 0;
		z-index: 100;
	}		
	/* ELEMENT */
	h3 {}
	ul {
		max-height: 250px;
		overflow-y: auto;
	}
	li {}
	a {}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}