/* ==============================================================
   CMS Template "Generic"
   style declarations for cms_template_01_generic.xhtml
   ==============================================================*/
/* GENERIC */
/* BLOCK */
.tpl-generic {
    /* ELEMENT */
    &__content {
        margin-bottom: 20px;
    }
    &__image {
        float: left;
        width: 25%;
        margin-right: 15px;
        figcaption {
            padding: 5px 0;
            font-style: italic;
            font-size: 0.9rem;
            color: @middlegray;
        }
    }
    &__text {}
    /* SOLR-QUERY */
    .solr-query-collection {
    	margin-top: 20px;
    	padding-top: 20px;
    	border-top: 1px solid @middlegray;
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
    .tpl-generic__image {
        width: 100%;
        margin: 0 0 10px 0;
    }
}