/* ==============================================================
   Login Navigation
   style declarations for loginNavigation.xhtml
   ==============================================================*/
/* LOGIN NAVIGATION */
/* BLOCK */
.login-navigation {
	position: relative;
	.mx-list-reset;
	/* ELEMENT */
	/* NOT LOGGED IN */
	[data-login="false"] {
		button[data-toggle="login-dropdown"] {
			padding: 6px 0;
			span {}
			.fa {
				margin-left: 5px;
			}
		}
		.login-navigation {
			/* LOGIN DROPDOWN */
			&__login-dropdown {
				display: none;
				position: absolute;
				top: 100%;
				right: 0;
				width: 275px;
				min-height: 290px;
				padding: 10px;
				font-size: 1rem;
				border: 1px solid @middlegray;
				background-color: @white;
				overflow: hidden;
				z-index: 999;
				li {
					border-bottom: 1px solid @middlegray;
					&:hover {
						background-color: @lightgray;
					}
				}
				a, button {
					padding: 7px 14px;
				}
				.control-label {
					font-weight: normal;
				}
				.form-control {
					height: 32px;
				}
			}
			/* DROPDOWN ACTIONS */
			&__login-dropdown-action {
				margin-top: 30px;
				.btn {
					width: 100%;
				}
			}
			/* DROPDOWN OPTIONS */
			&__login-dropdown-options {
				ul {
					.mx-list-reset;
					margin-right: -10px;
					margin-left: -10px;
				}
				li {
					&:hover {
						background-color: transparent;
					}
				}
				a, button {
					width: 100%;
					padding: 7px 14px;
					font-size: 0.9rem;
					color: @blue;
				}
				a {
					display: block;
					text-align: center;
				}
			}
			/* OPEN ID */
			&__open-id {
				h3 {
					margin: 0;
					padding: 7px 0;
					font-size: 0.9rem;
					text-align: center;
				}
				li {
					border: none;
					a {
						padding: 5px 10px 5px 5px;
						font-size: 21px;
					}
					&:hover,
					&:active,
					&:focus {
						background-color: transparent;
					}
				}
			}
		}		
	}
	/* LOGGED IN */
	[data-login="true"] {
		button[data-toggle="user-dropdown"] {
			display: flex;
		    align-items: center;
		    justify-content: center;
		    padding: 6px 0;
		}
		.login-navigation {
			/* TOGGLE USER DROPDOWN */
			&__user-name {
				max-width: 150px;
				padding-right: 5px;
				padding-left: 5px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}			
			&__user-icon {
				display: flex;
				padding-right: 5px;
				padding-left: 5px;
				img {
					max-height: 16px;
				}
			}
			/* USER DROPDOWN */
			&__user-dropdown {
				display: none;
				position: absolute;
				top: 100%;
				right: 0;
				width: 275px;
				padding: 10px;
				padding-top: 0;
				font-size: 1rem;
				border: 1px solid @middlegray;
				background-color: @white;
				overflow: hidden;
				z-index: 999;
				ul {
					.mx-list-reset;
					margin-right: -10px;
					margin-left: -10px;
				}
				li {
					margin: 0;
					border-bottom: 1px solid @middlegray;
				}
			}
			&__user-dropdown-item {
				display: flex;
				align-items: center;
				font-size: 0.8rem;
			}
			&__user-dropdown-item-icon {
				min-width: 10%;
				padding-left: 15px;
				.fa {
					color: @blue;
				}
			}
			&__user-dropdown-item-string {
				a {
					display: block;
					padding: 7px 15px;
					color: @black;
					&:hover,
					&:active,
					&:focus {
						text-decoration: none;
					}
				}
			}
			/* USER DROPDOWN ACTION */
			&__user-dropdown-action {
				padding-top: 10px;
				.btn {
					width: 100%;
					margin: 0;
					&:hover,
					&:active,
					&:focus {}
				}
			}
		}
	}	
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {} 