/* ==============================================================
   View Timematrix
   style declarations for viewTimematrix.xhtml
   ==============================================================*/
#timeMatrix {
    h2 {}
    p {}
    h3 {}
    .timematrix-selection {
        float: left;
        width: 100%;
        padding: 1.875rem;
        margin-bottom: 1.3125rem;
        #slider-range {
        	height: 12px;
        	border-color: @middlegray;
            .ui-slider-range {}
            .ui-slider-handle {
                display: block;
                position: relative;
                float: left;
                width: 20px;
                height: 20px;
                border: 1px solid @middlegray;
                border-radius: 0;
                background: @lightgray;
                cursor: pointer;
                z-index: 998;
                &.top {
                	position: absolute;
                	z-index: 999;
                }
                &:focus {
            		outline: none;
            	}
                .timematrix-slider-bubble-startDate,
                .timematrix-slider-bubble-endDate {
                    position: absolute;
                    top: -40px;
                    left: 50%;
                    min-width: 60px;
                    height: 30px;
                    line-height: 28px;
                    margin-left: -30px;
                    padding: 0 5px;
                    font-size: 14px;
                    text-align: center;
                    border: 1px solid @middlegray;
                    border-radius: 3px;
                    background: @lightgray;
                    &:before {
                        content: "";
                        position: absolute;
                        bottom: -20px;
                        left: 50%;
                        width: 0;
                        height: 0;
                        margin-left: -10px;
                        border-color: @middlegray transparent transparent transparent;
                        border-style: solid;
                        border-width: 10px;
                    }
                }
            }
        }
    }
    .btn {
        float: right;
        margin: 1.3125rem 0 0 0;
    }
}

#timematrixCanvas {
	position: relative;
    margin-bottom: 1.3125rem;
    .timematrix-box {
        .timematrix-header {
            h3 {
                margin: 0;
            }
        }
        .timematrix-body {
            .mx-clearfloats;
            .timematrix-thumb {
                position: relative;
                float: left;
                width: 12.5%;
                border: 1px solid @middlegray;
                cursor: pointer;
                overflow: hidden;
                transition: all .3s;
                &:hover {
                    border: 2px solid red;
                }
                img {
                	display: block;
                	margin: 0 auto;
                	width: 100%;
                }
                &.marker:before {
                	content: "";
                	display: block;
                	position: absolute;
                	width: 0;
                	height: 0;
                	bottom: 0;
                	left: 50%;
                	margin-left: -10px;
                	border-width: 10px;
                	border-style: solid;
                	border-color: transparent transparent @middlegray transparent;
                	z-index: 1;
                }
            }
        }
        .timematrix-footer {}
    }
}

.timematrix-popover {
    position: absolute;
    width: 18.75rem;
    font-size: 1rem;
    border: 1px solid @middlegray;
    border-radius: 0;
    background-color: @lightgray;
    z-index: 9999;
    .timematrix-popover-close {
        position: absolute;
        top: 0;
        right: 0.3125rem;
        font-weight: bold;
        cursor: pointer;
        z-index: 9999;
    }
    .timematrix-popover-header,
    .timematrix-popover-body,
    .timematrix-popover-footer {
        position: relative;
        padding: 10px;
    }
    .timematrix-popover-header {
        height: 4.375rem;
        padding: 0.9375rem 0.625rem 0.625rem 0.625rem;
        border-bottom: 1px solid @middlegray;
        h4 {
            margin: 0;
        }
    }
    .timematrix-popover-body {
        height: 16.875rem;
        line-height: 15.3rem;
        text-align: center;
        background-color: @white;
        .timematrix-popover-imageloader {
            position: absolute;
            width: 17.5rem;
            height: 15.625rem;
            background-image: url("@{images}/image_preloader.gif");
            background-repeat: no-repeat;
            background-position: center center;
            background-color: @white;
        }
        a {
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .timematrix-popover-footer {
        height: 40px;
        text-align: right;
        border-top: 1px solid @middlegray;
        a {}
    }
}

@media screen and (max-width: 400px) {
	#timematrixCanvas {
	    .timematrix-box {
	        .timematrix-header {
	            h3 {}
	        }
	        .timematrix-body {
	            .timematrix-thumb {
	            	width: 33.33333%;
	                &:hover {}
	                img {}
	                &.marker:before {}
	            }
	        }
	        .timematrix-footer {}
	    }
	}	
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}