/* ==============================================================
   View Mirador
   style declarations for viewMirador.xhtml 
   ==============================================================*/
/* VIEW MIRADOR */
/* BLOCK */
.view-mirador {
	/* ELEMENT */
	&__viewer {
		width: 100vw;
		max-width: 100%;
		height: 100vh;
		max-height: 100%;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}