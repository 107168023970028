/* ==============================================================
   SearchTermList
   style declarations for searchTermList.xhtml
   ==============================================================*/
/* BLOCK */
.search-termlist {
	/* ELEMENT */
	&__list {
	    margin-bottom: 1.3125rem;
	    ul {
			column-count: 3;
			column-gap: 15px;	    	
	    }
	    li {
	    	break-inside: avoid;
	    }
	    a {
	    	display: block;
	    	padding: 5px 10px;
	    	text-overflow: ellipsis;
	    	overflow: hidden;
	    }
	}	
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {
	.search-termlist__list ul {
		column-count: 2;
	}
}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.search-termlist__list ul {
		column-count: 1;
		column-gap: 0;
	}
}