/* ==============================================================
   View Download
   style declarations for viewDownload.xhtml
   ==============================================================*/
/* DOWNLOAD */
/* BLOCK */
.download {
	/* ELEMENT */
	&__status {
		h2 {}
		.panel {}
		.panel-heading {}
		.panel-title {
			margin: 0;
			padding: 0;
		}
		.panel-body {}
	}
	&__status-loader {
		.mx-clearfloats;
		img {
			float: right;
			max-height: 16px;
		}
	}
	&__status-license-text {
        height: 200px;
        background-color: @white;
        overflow-y: auto;
    }
}

#downloadBtn {
	margin: 10px 0;
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}