/* ==============================================================
   Feedback
   style declarations for feedback.xhtml
   ==============================================================*/
/* FEEDBACK */
/* BLOCK */
.view-feedback {
	/* ELEMENT */
	h2 {}
	p {}
    .form-group {
	    label {}
	    input {}
        textarea {
            min-height: 150px;
            resize: vertical;
        }    	
	    .btn {
	    	margin: 0;
	    }	
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}