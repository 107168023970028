/* ==============================================================
   View Metadata
   style declarations for viewMetadata.xhtml 
   ==============================================================*/
/* METADATA */
/* BLOCK */
.metadata {
	position: relative;
    margin-bottom: 1.3125rem;
    .fa {
    	margin-right: 5px;
    }
	/* ELEMENTS / EVENTS */
	&__elements,
	&__events {}
	&__element,
	&__event {}
	&__element-dl,
	&__event-dl {
		margin: 0;
		dt {
			width: 30%;
			text-align: left;			
		}
		dd {
			margin-left: 30%;			
		}
	}
	&__element-value,
	&__event-value {
		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		&:last-child {
			margin-bottom: 0;
		}
	}
	/* ELEMENT */
	&__elements {
		&.level-0 {}
        &.level-1 {}
        &.level-2 {}
        &.level-3 {}
        &.level-4 {}
        &.level-5 {}
        &.level-6 {}
        &.level-7 {}
        &.level-8 {}
        &.level-9 {}
	}
	&__elements-h3 {
		display: none;
	}
	&__element {}
	&__element-dl {
		dt {}
		dd {}
	}
	&__element-value {}
	/* EVENT */
	&__events {}
	&__event {}
	&__event-dl {
		dt {}
		dd {}
	}
	&__event-value {}
}

/* DOWNLOADS */
#viewMetadataDownloads {
    margin-bottom: 1.3125rem;
    ul {
        li {
            display: inline-block;
            margin-bottom: 4px;
            a {}
            .btn {}
            /* BLOCK */
            &.dl-links {
                /* ELEMENT */
                &__mets {}
                &__mets-all {}
                &__marcxml {}
                &__dc {}
                &__ese {}
                &__dfg {}
                &__opac {}
                &__pdf {}
                &__epub {}
            }
        }
    }
}


/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {
	.metadata {
		&__element-dl,
		&__event-dl {
			dt {
				width: 100%;			
			}
			dd {
				width: 100%;
				margin: 0;			
			}
		}
	}
}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}