/* 
 * message box 
 * */
.messages {
    position: fixed;
    top: 3.125rem;
    right: 4.6875rem;
    max-width: 18.75rem;
    z-index: 99999;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

/* WARNING POPOVER */
.warning-popover {
    position: fixed;
    top: 0;
    right: 15px;
    left: 15px;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ebccd1;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background-color: #f2dede;
    color: #a94442;
    z-index: 9999;
    button {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 10px;
        font-size: 18px;
    }
    p {
        margin: 0;
    }
}

.img-flexible {
    width: 100%;
    max-width: 100%;
    height: auto;
}

.container-fluid {
    padding: 0;
}

/* AJAX Loaders */
#AJAXLoader,
#AJAXLoaderSidebarToc {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 999;
}

#fullscreenTemplate #AJAXLoader,
#image_container #AJAXLoader {
    background-image: url("@{images}/image_preloader.gif");
    background-repeat: no-repeat;
    background-position: center center;
}

#searchgroup #AJAXLoader {
    background-image: url("@{images}/ajax_preloader.gif");
    background-repeat: no-repeat;
    background-position: center center;
}

/* tinyMCE */
div.mce-fullscreen {
    z-index: 999;
}

/* IE 11 NOT SUPPORTED */
.support-overlay {
	.mx-hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	background-color: rgba(0,0,0,0.75);
	&__box {
		position: absolute;
		width: 400px;
		height: 600px;
		top: 50%;
		left: 50%;
		margin: -300px 0 0 -200px;
		border: 1px solid @middlegray;
		background-color: @white;
	}
	&__box-header,
	&__box-body,
	&__box-footer {
		padding: 15px;
	}
	&__box-header {
		height: 50px;
		padding-top: 13px;
		padding-bottom: 0;
		border-bottom: 1px solid @middlegray;
		h3 {
			margin: 0;
		}
	}
	&__box-body {
		height: 500px;
	}
	&__box-footer {
		height: 50px;
		padding-top: 13px;
		padding-bottom: 0;
		text-align: right;
		border-top: 1px solid @middlegray;
	}
}
html.is-IE {
	.support-overlay {
		display: block !important;
		visibility: visible !important;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}