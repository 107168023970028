/* ==============================================================
   LESS Mixins
   ==============================================================*/
/* CLEARFLOATS */
.mx-clearfloats {
	&:before,
    &:after {
    	content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}
/* BACKGROUND IMAGE */
.mx-bgi(@image; @pX: center; @pY: center; @size: auto) {
	background-image: url(@image);
	background-repeat: no-repeat;
	background-position: @pX @pY;
	-webkit-background-size: @size;
	background-size: @size;
}
/* CSS TRIANGLE */
.mx-triangle(@top: 0; @left: 0; @bWidth: 10px; @bcTop: transparent; @bcRight: transparent; @bcBottom: #000; @bcLeft: transparent) {
	position: relative;
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: @top;
		left: @left;
		width: 0;
		height: 0;
		border-width: @bWidth;
		border-style: solid;
		border-color: @bcTop @bcRight @bcBottom @bcLeft;
	}
}
/* HIDDEN */
.mx-visible {
	display: block !important;
	visibility: visible !important;
}
/* HIDDEN */
.mx-hidden {
	display: none !important;
	visibility: hidden !important;
}
/* VISUALLY HIDDEN */
.mx-v-hidden {
    position: absolute; 
    width: 1px;
    height: 1px; 
	clip: rect(1px, 1px, 1px, 1px); 
    white-space: nowrap; 
    overflow: hidden; 
}
/* GRID */
.mx-grid(@width: 145px; @gap: 15px) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(@width, 1fr));
  grid-gap: @gap;
}
/* LIST RESET */
.mx-list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}