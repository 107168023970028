/* ==============================================================
   CMS Collections
   style declarations for adminCmsCollections.xhtml
   ==============================================================*/
/* CMS COLLECTIONS */
/* BLOCK */
.cms-collections {
	/* ELEMENT */
	&__selection {
		display: grid;
	    grid-template-columns: 1fr 3fr;
	    grid-gap: 15px;
	    align-items: center;
	    margin-bottom: 15px;
	}
	&__selection-label {
		label {}
	}
	&__selection-control {
		position: relative;
		display: flex;
    	align-items: center;
		.custom-control--select {
			margin-right: 24px;
			select {}
		}
		.ui-autocomplete {
			top: 100%;
		}
		.ui-widget {
			font-family: @fontFamily;
		    font-size: 0.9rem;
		    border-color: @middlegray;
		}
		.ui-state-focus {
			border-color: @lightgray;
			border-right-color: @middlegray;
			border-left-color: @middlegray;
			background-image: none;
			background-color: @lightgray;
		}
		input {}
		a {
			display: block;
    		padding: 6px;
    		color: @black;
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				color: @black;
			}
		}
	}
	&__table {
		margin: 15px 0;
		table {}
		thead {}
		tbody {}
		tr {}
		th {}
		td {
			img {}
			a {
				color: @black;
				&:hover,
				&:active,
				&:focus {
					text-decoration: none;
					color: @black;
				}
			}
			&.actions {
				text-align: right;
				a {
					display: inline-block;
					padding: 0 5px;
				}
			}
		}
	}
}

// BREAKPOINT FOR LARGE DESKTOPS
@media screen and (max-width: @largeBreakpoint) {}

// BREAKPOINT FOR DESKTOP
@media screen and (max-width: @mediumBreakpoint) {}

// BREAKPOINT FOR TABLETS
@media screen and (max-width: @smallBreakpoint) {}

// BREAKPOINT FOR SMARTPHONES
@media screen and (max-width: @xSmallBreakpoint) {}