/* ==============================================================
   CMS Template "News Overview"
   style declarations for cms_template_02_overview.xhtml
   ==============================================================*/
/* NEWS OVERVIEW */
/* BLOCK */
.tpl-news-overview {
    /* ELEMENT */
    &__content {
        margin-bottom: 30px;
    }
    &__image {
        float: left;
        width: 25%;
        margin: 0 10px 10px 0;
        margin-bottom: 15px;
        figcaption {
            padding: 5px 0;
            font-style: italic;
            font-size: 0.9rem;
            color: @middlegray;
        }
    }
    &__text {}
    &__list-item {
        margin-bottom: 15px;
    }
    &__list-image {
    	max-height: 150px;
    	overflow: hidden;
    }
    &__list-preview {}
    &__preview-date {
        margin-bottom: 15px;
    }
    &__preview-text {
        margin-bottom: 15px;
    }
    &__preview-continue {}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
    .tpl-news-overview__image {
        width: 100%;
        margin: 0 0 10px 0;
    }
}