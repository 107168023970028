/* ==============================================================
   Buttons
   style declarations for buttons
   ==============================================================*/
/* BUTTONS */
.btn {
	color: @btnDefault;
	border-radius: 0;
	background-color: transparent;
	&:hover,
	&:active,
	&:focus,
	&:active:focus,
	&.active,
	&.active.focus, 
	&.active:focus, 
	&.focus, 
	&:active.focus {
		color: inherit;
		outline: none;
		box-shadow: none;
	}
	/* MODIFICATION */
	&--default {
		border-color: @btnDefaultBorder;
		background-color: @btnDefaultBgc;
		&:hover,
		&:active,
		&:focus {
			background-color: @btnDefaultHoverBgc;
		}
	}
	&--success {
		color: @btnSuccess;
		border-color: @btnSuccessBorder;
		background-color: @btnSuccessBgc;
		&:hover,
		&:active,
		&:focus {
			color: @btnSuccess;
			background-color: @btnSuccessHoverBgc;
		}
	}
	&--danger {
		color: @btnDanger;
		border-color: @btnDangerBorder;
		background-color: @btnDangerBgc;
		&:hover,
		&:active,
		&:focus {
			color: @btnDanger;
			background-color: @btnDangerHoverBgc;
		}
	}
	&--full {
		color: @white;
		border-color: @blue;
		background-color: @blue;
		&:hover,
		&:active,
		&:focus {
			color: @white;
			background-color: @btnFullHoverBgc;
		}
	}
	&--clean {
		padding: 0;
		line-height: inherit;
		color: inherit;
		border: none;
	}
	&--link {
		padding: 0;
		color: @blue;
		border: none;
		&:hover,
		&:active,
		&:focus {
			text-decoration: underline;
			color: @btnDefault;
		}
	}
	&--hidden {
		display: none !important;
	}
	&--inactive {
		cursor: not-allowed;
		opacity: .65;
	}
	&--icon {
		padding: 2px;
		font-size: inherit;
		color: @black;
		border: none;
		&:hover,
		&:focus,
		&:active {
			color: @black;
		}
	}
}

/* MODIFICATIONS */
a.btn--icon { line-height: normal; }

@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}