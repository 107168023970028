/* ==============================================================
   Widget Search Drill Down
   style declarations for widget_searchDrillDown.xhtml
   ==============================================================*/
/* BLOCK */
.widget-search-drilldown {
	/* ELEMENT */
	.widget {
		ul {}
		li {
			&.active {
				margin-bottom: 3px;
				a {
					background-color: @lightgray;
				}
				.row {
					margin: 0;
				}
				.col-xs-11,
				.col-xs-1 {
					padding: 0;
					text-overflow: ellipsis;
					overflow: hidden;
				}
				.col-xs-1 {
					text-align: right;
				}
			}
		}
		a {
			display: block;
			padding: 3px 5px;
		}
	}
	&__collection,
	&__groups {
		ul {
			max-height: 400px;
			overflow-y: auto;
		}
	}
	&__collection {}
	&__groups {}
	/* COLLAPSE DRILLDOWN */
    &__collapse {
        a {
            display: block;
            text-align: center;
            color: @middlegray;
            background-color: @lightgray;
        }
    }
}


/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}