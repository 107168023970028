/* ==============================================================
   CMS Overview
   style declarations for adminCmsOverview.xhtml
   ==============================================================*/
#cmsOverview {
    h2 {}
    #cmsOverviewWrapper {
        #cmsOverviewTable_length {
            label {
                select {}
            }
        }
        #cmsOverviewTable_filter {
            text-align: right;
            label {
                input {
                    margin-left: 10px;
                }
            }
        }
        #cmsOverviewTable {
            thead {
                tr {
                    th {
                        &.column_template {}
                        &.column_language {
                            text-align: center;
                            width: 5%;
                        }
                        &.column_title {
                        }
                        &.column_classification {}
                        &.column_date_updated {
                        }
                        &.column_actions {
                            min-width: 100px;
                        }
                    }
                }
            }
            tbody {
                tr {
                    &.odd {}
                    &.even {}
                    &.invalid_template {
	                	opacity: 0.2;
	                }
                    td {
                        vertical-align: top;
                        &:nth-child(7) {
                            word-break: break-all;
                        }
                        &:nth-child(8) {
                            text-align: right;
                        }
                        ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            li {}
                        }
                        .btn {}
                        img {
                            width: 64px;
                        }
                        .cms-overview-flag-icon {
                            display: block;
                            margin: 0 auto;
                            max-width: 16px;
                            opacity: 0.25;
                            &.lang-complete {
                                opacity: 1;
                            }
                        }
                        .column_title {
	                        &__main_title {
	                        	display: block;
	                        }
	                        &__menu_title {
	                        	font-size: 12px;
	                        }
						}
                    }
                }
            }
        }
        #cmsOverviewTable_paginate {
            text-align: right;
        }
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}