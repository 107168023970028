/* ==============================================================
   CMS Media
   style declarations for adminCmsOverviewMedia.xhtml
   ==============================================================*/
#cmsOverviewMedia {}

/* TRIGGER UPLOAD */
/* BLOCK */
.overview-media__file-upload {
    margin-bottom: 15px;
    .btn {
        margin: 0;
    }
}

/* MEDIA GALLERY */
/* BLOCK */
.media-gallery {
    margin-bottom: 15px;
    /* ELEMENT */
    &__tag-filter {
        margin-bottom: 10px;
        .col-sm-4 {
        	padding: 0;
        }
    }
    &__tag-filter-label {
        display: inline-block;
        height: 34px;
	    padding-top: 9px;
        line-height: normal;
	    font-size: 14px;
    }
    &__item {
        margin: 7px 0;
        border: 1px solid @middlegray;
        background-color: @white;
    }
    &__item-image {
        position: relative;
        height: 125px;
        padding: 5px;
        border-radius: 4px 4px 0 0;
        overflow: hidden;
        [data-copy="img-url"] {
        	position: absolute;
        	top: 0;
        	right: 0;
        	padding: 10px;
        	z-index: 999;
        }
        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
    &__item-action-wrap {
        margin: 0;
        border-top: 1px solid @middlegray;
        background-color: @lightgray;
    }
    &__item-title,
    &__item-action {
        height: 45px;
        line-height: 45px;
    }
    &__item-title {
        text-overflow: ellipsis;
        overflow: hidden;
        span {}
    }
    &__item-action {
    	a {}
    }
}


/* MEDIA UPLOAD MODAL */
/* BLOCK */
.media-modal {
    /* ELEMENT */
    .modal-dialog {}
    .modal-content {}
    .modal-header {}
    .modal-title {}
    .modal-body {
    	.form-group {
    		margin-bottom: 3px;
    		label {
    			padding-left: 20px;
    		}
    	}
    }
    .modal-footer {}
    &__upload {
        padding: 10px 0;
        margin-bottom: 15px;
    }
    &__upload-input {
        &:focus,
        &:active {
            outline: none !important;
        }
    }
    &__metadata {
        margin-bottom: 15px;
        dl {
            margin: 0;
        }
        dt {
            width: 50px;
            text-align: left;
        }
        dd {
            margin-left: 50px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    &__metadata-tabs {}
    &__options {
        padding: 10px;
        border-right: 1px solid @middlegray;
        border-left: 1px solid @middlegray;
        border-bottom: 1px solid @middlegray;
        span.control-label {
            display: inline-block;
            padding-top: 6px;
            font-weight: bold;
        }
    }
    &__tags {
        padding: 6px 6px 4px 6px;
        border: 1px solid @middlegray;
        background-color: @white;
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }
        li {
            display: inline-block;
            margin-right: 3px;
            margin-bottom: 2px;
        }
        .tag {}
        .label {
            padding: .3em .6em .3em;
            font-weight: normal;
            background-color: @blue;
        }
        .tag-terminator {
            margin-left: 5px;
            cursor: pointer;
            &:after {
                content: "x";
            }
        }
        input {
            width: auto;
            max-width: inherit;
            margin: 0;
            padding: 0 6px;
            border: none;
            box-shadow: none;
            background-color: transparent;
            outline: none;
        }
        /* autocomplete */
        .ui-widget-content {
            border: 1px solid @middlegray;
            background-color: @white;
        }
        .ui-menu-item {
            display: block;
        }
        .ui-menu-item.ui-state-focus {
            border-color: @middlegray;
            background: @lightgray;
        }
    }
}


/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}