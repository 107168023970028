/* ==============================================================
   View Toc
   style declarations for viewToc.xhtml
   ==============================================================*/
/* VIEW TOC */
/* BLOCK */
.toc {
	margin-bottom: 15px;
	/* MULTI VOLUME TOC */
	/* ELEMENT */
	h4 {}
	&__multi-volume {
		margin-top: 15px;
	}
	&__mv-group {
        padding: 5px 0;
        button {
            width: 16px;
            height: 16px;
            line-height: 14px;
            margin-right: 10px;
            text-align: center;
            border: 1px solid @lightgray;
            span:before {
                content: "\2013";
            }
            &.active {
                span:before {
                    content: "+";
                }
            }
        }
        &.inactive {
            border-bottom: 2px solid @lightgray;
        }
    }
	&__mv-element {
		padding: 15px 0;
		border-bottom: 1px solid @middlegray;
		&:hover {
			background-color: @lightgray;
		}
		&.level-0 {
			background-color: transparent;
			h4 {
				display: none;
			}
		}
		a {
			display: block;
		}
		.row {
			margin: 0;
		}
	}
	&__mv-element-title {}
	&__mv-element-link {}
	&__mv-element-img {
		overflow: hidden;
	}
	/* TOC TREEVIEW */
	/* ELEMENT */
	&__list-expandable,
	&__list-full {
		border-top: 1px solid @middlegray;
		li {
			padding: 0 10px;
			border-bottom: 1px solid @middlegray;
			&:first-child {
				padding-left: 5px !important;
			}
			&:hover {
				background-color: @lightgray;
				a {
					color: @darkgray;
				}
			}
		}
	}
	&__list-expandable-title,
	&__list-full-title {
		a {
			display: block;
			padding: 5px 0;
			&:hover,
			&:focus,
			&:active {}
		}
	}
	&__list-expandable-number,
	&__list-full-number {
		li {
			display: inline-block;
			border: none;
		}
		a {
			display: block;
			padding: 5px 3px;
			text-decoration: none;
			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
			}
		}
	}
	/* EXPAND ALL */
	&__list-expandable-all {
		margin-top: 15px;
		padding: 0 15px;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		li {
			display: inline-block;
			float: right;
		}
		a {
			display: block;
			padding: 5px;
		}
	}
	/* TOC EXPANDABLE */
	&__list-expandable {}
	&__list-expandable-elem {
		&.parent {}
		&.child {}
	}
	&__list-expandable-expand {
		a {
			display: block;
			padding: 5px 0;
		}
	}
	&__list-expandable-title {
		a {
			&:hover,
			&:focus,
			&:active {}
		}
	}
	&__list-expandable-number {
		li {}
		a {
			&:hover,
			&:focus,
			&:active {}
		}
	}
	/* TOC FULL */
	&__list-full {
		margin-top: 15px;
	}
	&__list-full-title {
		a {
			&:hover,
			&:focus,
			&:active {}
		}
	}
	&__list-full-number {
		li {}
		a {
			&:hover,
			&:focus,
			&:active {}
		}
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}