/* ==============================================================
   User Data
   style declarations for user.xhtml
   ==============================================================*/
/* USERDATA */
/* BLOCK */
.userdata {
	/* ELEMENT */
	h3 {}
	&__gravatar {
		img {
			display: block;
		}
	}
	&__data {
        overflow-x: auto;
	}
	&__action {
		text-align: right;
		.btn {
			margin: 0;
		}
	}
}

#editUserdataModal {
	.checkbox input[type=checkbox], 
	.checkbox-inline input[type=checkbox] {
		margin-left: -15px;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.userdata__gravatar img { margin: 0 auto 15px auto; }
	.userdata__action { text-align: center; }
}