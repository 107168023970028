/* ==============================================================
   Searchlist Grid View
   style declarations for searchList.xhtml
   ==============================================================*/
/* SEARCH LIST GRID */
/* BLOCK */
.search-list {
    /* ELEMENT */
    /* HITS */
    &__hits {
		&.grid {
			form {
				display: grid;
			    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
			    grid-gap: 10px;
			    grid-auto-rows: minmax(300px, auto);
			    grid-auto-flow: dense;				
			}
			.search-list__hit {
				margin: 0;
				padding: 0;
				.row,
				[class*="col-"] {
					float: none;
					width: auto;
					margin: 0;
					padding: 0;
				}
			}
			.search-list__hit-header {
				.row {
				    display: flex;
				    flex-direction: column;
				}
				.col-xs-12.col-sm-10 {
					order: 2;
				}
				.col-xs-12.col-sm-2 {
					order: 1;
				}
			}
			.search-list__hit-thumbnail {
				min-height: 175px;
				background-repeat: no-repeat;
				background-position: center top;
				background-size: cover;
				a {
					display: block;
					height: 175px;
				}
				[data-link="related-page"] {
			     	top: 5px;
			    }
				[data-bookshelf-type="add"] {
					top: 0;
					right: 0;
					padding: 5px;
				}
			}
			.search-list__hit-title h4 {
			    text-align: center;
			    padding: 10px;
			    text-overflow: ellipsis;
			    overflow: hidden;
			}
			.search-list__hit-thumbnail img,
			.search-list__hit-type,
			.search-list__hit-info,
			.search-list__hit-content {
				.mx-hidden;
			}
		}
	}
	a[data-toggle="hit-content"],
	button[data-toggle="hit-content"] {
		display: none;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}