/* ==============================================================
   User
   style declarations for user.xhtml
   ==============================================================*/
/* USER LOGIN */
/* BLOCK */
.user-login {
	/* LOCAL */
	&__local {}
	/* EXTERNAL */
	&__external {
		.user-login {
			&__options {
				li {
					text-align: right;
				}
			}
		}
	}
	/* OPEN ID */
	&__openid {}
	/* ELMENTS */
	&__action {
		.btn {
			margin: 0;
		}
	}
	&__options {}
}

/* USER LOGIN CARD */
.user-login {
	&__card {
		height: 100%;
		border: 1px solid @middlegray;
		h3 {
			margin: 0;
			padding: 10px 15px;
			border-bottom: 1px solid @middlegray;
			background-color: @lightgray;
		}
	}
	&__card-body {
		padding: 15px;
	}
}

/* MODALS */
#createAccountModal p { margin: 0; }
#createAccountModal .modal-footer { margin: 0; }

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}