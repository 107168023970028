/* ==============================================================
   IE lower EDGE Styles
   ==============================================================*/
 /* IS IE */
.is-IE {
	/* CUSTOM DROPDOWNS */
	.custom-control--select:after {
		display: none;
	}
	.custom-control--select select {
		padding: 0;
	}
}