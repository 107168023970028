/* ==============================================================
   CMS Template "Feedback"
   style declarations for cms_template_19_feedback.xhtml
   ==============================================================*/
/* TPL FEEDBACK */
/* BLOCK */
.tpl-feedback {
    h1 {}
    a {}
    /* ELEMENT */
    &__text {
    	margin-bottom: 20px;
    }
    .form-group {
        label {}
        input {}
        .btn {
            margin: 0;
        }
        textarea {
            min-height: 150px;
            resize: vertical;
        }
    }
	/* MODIFICATION */
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}