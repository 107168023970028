/* ==============================================================
   Widget Convolutes
   style declarations for widget_convolutes.xhtml
   ==============================================================*/
#widgetConvolutes {
    display: none;
    visibility: hidden;
}

.widget-convolutes {
    &__series {}
    &__anchor {}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}
