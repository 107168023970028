/* ==============================================================
   Resets for CSS Libs
   ==============================================================*/
 /* BOOTSTRAP */
 .page-header {
    padding-bottom: 0;
    margin: 0;
    border-bottom: none;
}
.nav-tabs {
	li {
		a {
			border-bottom: 1px solid @middlegray;
			background-color: @lightgray;
			border-radius: 0;
			border-color: @middlegray;
		}
		span {
			display: block;
			position: relative;
			line-height: 1.42857;
			margin-right: 2px;
			padding: 10px 15px;
			border: 1px solid transparent;
			border-bottom: none;
			border-radius: 0;
		}
		&.active {
			a {
				border-color: @middlegray;
				border-bottom: 1px solid @white;
				background-color: @white;
			}
		}
	}
}
.tab-pane {
	padding: 10px;
	border-right: 1px solid @middlegray;
	border-bottom: 1px solid @middlegray;
	border-left: 1px solid @middlegray;
}
.form-control {
    border-radius: 0;
    box-shadow: none;
    &:focus {
        border-color: @blue;
        box-shadow: none;
        outline: none;
    }
}
.form-horizontal {
    .form-group {
        label {
            padding-left: 0.9375rem;
            text-align: left;
        }
        .form-control {}
        .btn {}
    }
}
.popover-content {
	color: @black;
}
.table > tbody > tr > td {
	word-break: break-word;
}
/* BS GRID RESETS */
.row {
	&.no-margin {
		margin: 0;
	}
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6,
.col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6,
.col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6,
.col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
.col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
	&.no-padding { 
		padding:0;	
	}
}
.no-gutters {
	margin-right: 0;
	margin-left: 0; 
	> [class*="col-"] { 
		padding-right : 0;
		padding-left: 0;
	}
}

/* CHROME RESETS */
button {
	border-radius: 0;
}
*:focus {
	outline: none;
}