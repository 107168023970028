/* ==============================================================
   Bookshelves
   style declarations for all bookshelf actions,
   ==============================================================*/
/* BOOKSHELF NAVIGATION */
/* BLOCK */
.bookshelf-navigation {
	/* ELEMENT */
	&__dropdown-wrapper {
		position: relative;		
	}
	/* TOOGLE DROPDOWN */
	&__counter {
		display: inline-flex;
    	align-items: center;
	    justify-content: center;
	    min-width: 15px;
	    margin-right: 5px;
		padding: 0 3px;
		font-size: 12px;
		color: @white;
		border-radius: 2px;
		background-color: @blue;
	}
	&__string {
		margin-right: 5px;
	}
	&__icon {}
	/* DROPDOWN */
	&__dropdown {
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		width: 275px;
		border: 1px solid @middlegray;
		background-color: @white;
		z-index: 999;
	}
	/* DROPDOWN LIST */
	&__dropdown-list {
		max-height: 300px;
		overflow-y: auto;
		li {
			padding: 2px 5px;
			border-bottom: 1px solid @middlegray;
			&:last-child {
				border-bottom: none;
			}
			.empty {
				padding: 5px;
			}
		}
		h4 {
			margin: 0;
		    padding: 5px;
		    font-size: 14px;
		}
		a {
			display: block;
			width: 100%;
			padding: 5px;
    		text-overflow: ellipsis;
			overflow: hidden;
		}
	}
	&__dropdown-list-counter {
		display: inline-block;
		float: right;
		margin-top: 5px;
		padding: 0 3px;
		font-size: 12px;
		color: @white;
		border-radius: 2px;
		background-color: @blue;		
	}
	&__dropdown-list-image {
		height: 75px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}
	&__dropdown-list-remove {
		text-align: center;
		[data-bookshelf-type="delete"] {
			width: 16px;
			height: 16px;
			color: @blue;
		}
	}
	/* DROPDOWN ACTIONS */
	&__dropdown-list-actions {
		border-top: 1px solid @middlegray;		
	}
	&__dropdown-list-reset,
	&__dropdown-list-send,
	&__dropdown-list-mirador {
		font-size: 13px;
		border-bottom: 1px solid @middlegray;
		a, button {
			display: flex;
			align-items: center;
		    justify-content: space-between;
		    width: 100%;
			padding: 10px;
			color: @blue;
		}
	}
	&__dropdown-list-reset {
		button {
			span {}
			.fa {}
		}
	}
	&__dropdown-list-send,
	&__dropdown-list-mirador {
		a {
			span {}
			.fa {}
		}
	}
	&__dropdown-list-send {}
	&__dropdown-list-mirador {
		border: none;
	}
}

/* POPUPS */
/* BLOCK */
.bookshelf-popup {
	position: absolute;
	width: 280px;
	height: 300px;
	border: 1px solid @middlegray;
	background-color: @white;
	box-shadow: 0px 2px 5px rgba(0,0,0,.15);
	z-index: 99999;
	&.top {}
	&.right {}
	&.bottom {
		&:before {
			content: "";
			position: absolute;
			display: block;
			top: -13px;
			left: 50%;
			width: 0;
			height: 0;
			margin-left: -3px;
			border-width: 6px;
			border-style: solid;
			border-color: transparent transparent @middlegray transparent;
		}
	}
	&.left {}
	/* ELEMENT */
	&__header,
	&__body,
	&__footer {
		position: absolute;
		right: 0;
		left: 0;
	}
	&__header,
	&__footer {
		display: flex;
		align-items: center;
	}
	&__header {
		top: 0;
		height: 30px;
		padding: 0 10px;
		border-bottom: 1px solid @middlegray;
	}
	&__body {
		top: 30px;
		max-height: 229px;
		overflow-y: auto;
	}
	&__body-loader {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url("@{images}/infinity_loader.svg");
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 25%;
		background-color: @white;
		z-index: 100000;
	}
	&__body-list {
		li {
			padding: 2px 5px;
			border-bottom: 1px solid @middlegray;
			.empty {
				padding: 5px;
			}
		}
		[data-bookshelf-type="add"] {
			width: 100%;
			padding: 5px;
		    text-align: left;
    		text-overflow: ellipsis;
			overflow: hidden;
			background-image: none;
			.fa {
				color: green;
			}
			span {
				display: inline-block;
			    float: right;
			    padding: 0 3px;
			    font-size: 12px;
			    color: @white;
			    border-radius: 2px;
			    background-color: @blue;
			}
		}
	}
	&__footer {
		bottom: 0;
		height: 40px;
		padding: 0 10px;
		border-top: 1px solid @middlegray;
		.row {
			width: 100%;
		}
		input {
			width: 100%;
			height: 38px;
			margin: 1px 0;
			border: none;
		}
		[data-bookshelf-type="add"] {
			display: block;
			width: 100%;
			height: 40px;
			background-image: url("@{images}/icons/icon-bs-add.svg");
			background-size: 60%;
			&.added {
				background-image: url("@{images}/icons/icon-bs-add.svg");
			}
		}
	}
	&__list {}
}

/* ACTION BUTTONS */
[data-bookshelf-type="dropdown"],
[data-bookshelf-type="get"],
[data-bookshelf-type="add"],
[data-bookshelf-type="add-bookshelf"],
[data-bookshelf-type="delete"],
[data-bookshelf-type="link"],
[data-bookshelf-type="reset"] {
	background-repeat: no-repeat;
	background-position: center center;	
}

[data-bookshelf-type="counter"] {
	visibility: hidden;
	&.in {
		visibility: visible;
	}
}

[data-bookshelf-type="dropdown"] {
	padding: 6px 0;
}

[data-bookshelf-type="get"] {}

[data-bookshelf-type="add"] {
	background-image: url("@{images}/icons/icon-bs-herz-blau_thin.svg");
	background-size: 72%;
	&.white {
		background-image: url("@{images}/icons/icon-bs-herz-weiss_thin.svg");
	}
	&.added {
		background-image: url("@{images}/icons/icon-bs-herz-blau.svg");
		&.white {
			background-image: url("@{images}/icons/icon-bs-herz-weiss.svg");
		}	
	}
}

[data-bookshelf-type="delete"] {}

[data-bookshelf-type="link"] {
	background-image: url("@{images}/icons/icon-bs-link.svg");
}

[data-bookshelf-type="reset"] {}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.bookshelf-navigation {
		&__string {
			display: none;
		}
	}
}