/* ==============================================================
   User Bookshelf Edit
   style declarations for userBackendEditBookshelf.xhtml
   ==============================================================*/
/* USER BOOKSHELF EDIT */
/* BLOCK */
.user-bookshelf-edit {
	/* ELEMENT */
	h2 {}
	&__form {
	    padding: 30px 15px;
    	border: 1px solid @middlegray;
    	form {
	    	width: 80%;
	    	margin: 0 auto;
    	}
    }
    .control-label {
		font-weight: normal;
	}
	.form-control {
		height: 48px;
	}
	textarea {
		min-height: 125px;
		resize: vertical;
	}
	&__action {
		text-align: right;
	}
	&__action {
		.btn {
			margin-left: 10px;
		}
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}