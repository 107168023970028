/* ==============================================================
   Admin Add User
   style declarations for adminUser.xhtml
   ==============================================================*/
#adminUser {
    h2 {}
}

// single tabs
#userData {}

#licenses {
    h3 {}
    p {
        text-align: right;
    }
}

/* EDIT USER */
#editUserGeneral {
	.btn {
		margin-left: 5px;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}