/* ==============================================================
   Forms
   style declarations for forms
   ==============================================================*/
/* INPUT MODIFICATIONS */
.form-control {
	&__select {
	    height: 34px;
	    padding: 6px;
	    font-size: 14px;
	    line-height: 1.42857143;
	    color: @black;
	    background-color: @white;
	    background-image: none;
	    border: 1px solid @middlegray;
	    select {
	    	width: 100%;
	    	height: 100%;
	    	margin: 0;
	    	padding: 0;
	        border: none;
	        background: @white;
	    }
	    select[disabled="disabled"] {
	        cursor: not-allowed;
	        opacity: 0.5;
	    }
	}
	&--multi-select {
		height: 125px;
		overflow-y: auto;
	}
}

/* CUSTOM CONTROL */
/* BLOCK */
.custom-control {
    position: relative;
    padding-right: 15px;
    color: @black;
    cursor: pointer;
    /* ELEMENT */
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
    &__icon {
        color: @blue;
    }
    .fa-check-circle-o,
    .fa-check-square-o {
    	display: none;
    }
	/* MODIFICATION */
	&--checkbox {
		input[type="checkbox"] {
	        &:checked ~ .custom-control__icon .fa-square-o {
	            display: none;
	        }
	        &:checked ~ .custom-control__icon .fa-check-square-o {
	            display: inline-block;
	        }
	    }
	}
	&--radio {
	    input[type="radio"] {
	        &:checked ~ .custom-control__icon .fa-circle-o {
	            display: none;
	        }
	        &:checked ~ .custom-control__icon .fa-check-circle-o {
	            display: inline-block;
	        }
	    }
	}
	&--select {
		width: 100%;
		height: 34px;
	    padding: 0 6px;
	    font-size: 14px;
	    line-height: 1.42857143;
	    color: @black;
	    background-color: @white;
	    background-image: none;
	    border: 1px solid @middlegray;
	    &:after {
		    content: "";
		    position: absolute;
	    }
	    &:after {
	        display: inline-block;
	        top: 50%;
	        right: 10px;
	        width: 0;
	        height: 0;
	        margin-top: -.15rem;
	        pointer-events: none;
	        border-top: .35rem solid @black;
	        border-right: .35rem solid transparent;
	        border-bottom: .35rem solid transparent;
	        border-left: .35rem solid transparent;
	    }
	    select {
	        display: inline-block;
	        width: 100%;
	    	height: 100%;
	        margin: 0;
	        padding-right: 26px;
	        line-height: 1.5;
	        color: @black;
	        border: 0;
	        border-radius: .25rem;
	        background-color: @white;
	        cursor: pointer;
	        outline: 0;
	        -webkit-appearance: none;
	        -moz-appearance: none;
	        appearance: none;
	    }
	    select[disabled="disabled"] {
	        cursor: not-allowed;
	        opacity: 0.5;
	    }
	}
}

/* INPUT PLACEHOLDER */
/* WebKit, Blink, Edge */
::-webkit-input-placeholder { 
    color: @placeholder;
}
/* Mozilla Firefox 4 to 18 */
:-moz-placeholder {
    color: @placeholder;
    opacity: 1;
}
/* Mozilla Firefox 19+ */
::-moz-placeholder {
    color: @placeholder;
    opacity: 1;
}
/* Internet Explorer 10-11 */
:-ms-input-placeholder {
    color: @placeholder;
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}