/* ==============================================================
   Widget Geo Locations
   style declarations for widget_geoLocations.xhtml
   ==============================================================*/
.widget-geo-locations {
	position: relative;
	h3 {}
	&__map {
		min-height: 350px;
		.mapboxgl-popup-content {
		    padding: 20px 15px 10px 15px;
		}
	}
    /* CMS BACKEND WIDGET */
	h5 {
    	margin-bottom: 5px;
    }
    &__input {
    	.mx-clearfloats;
    	margin-bottom: 20px;
    	padding-bottom: 15px;
    	border-bottom: 1px solid @middlegray;
    	.row {
    		margin: 0;
    	}
    	.col-xs-5,
    	.col-xs-6,
    	.col-xs-10 {
    		margin-bottom: 5px;
    		padding-right: 5px;
    		padding-left: 0;
    	}
    	textarea {
    		resize: vertical;
    	}
    }
    &__input-spacer {
    	margin: 10px 0;
    	width: 100%;
    	height: 1px;
    	background-color: @middlegray;
    }
    &__action {
    	position: relative;
    	z-index: 10;
    	.col-xs-2 {
		    margin: -32px 0 0 -6px;
    		padding: 0 0 21px 0;
		    text-align: right;
		    border-bottom: 1px solid @middlegray;
    	}
    	a {
    		&:hover,
    		&:focus,
    		&:active {
    			text-decoration: none;
    		}
    	}
    }
    &__overlay-check {
    	.mx-clearfloats;
    	float: left;
    	width: 100%;
    	.checkbox {
    		margin: 0;
    	}
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}