/* ==============================================================
   Image Controls
   Style declarations for imageControls.xhtml
   ==============================================================*/
/* CONTROLS */
/* BLOCK */
.image-controls {
	position: relative;
	padding: 15px 0;
    /* ELEMENT */
	/* DOCUMENT OPTIONS */
	&__options {
		ul {
			display: flex;
			flex-wrap: wrap;
    		justify-content: flex-end;
		}
	    li {
	    	align-self: center;
	    }
	}
	&__option {
		width: 21px;
		height: 21px;
		a, 
		span, 
		button {
			color: @gray;
			&:hover,
			&:active,
			&:focus {
				color: @gray;
			}
		}
		a, button {
			display: block;
			width: 100%;
			height: 100%;
			text-align: center;
		}
		&.pdf {}
		&.fulltext {}
		&.add-to-bookshelf {
			[data-bookshelf-type="add"] {
			    background-image: none;
			    span {
				    .fa-bookmark-o {
				        display: inline-block;
				    }
				    .fa-bookmark {
				        display: none;
				    }
			    }
			    &.added, 
			    &:hover {
			    	span {
				        .fa-bookmark-o {
				            display: none;
				        }
				        .fa-bookmark {
				            display: inline-block;
				        }		    		
			    	}
			    }
			}
		}
		&.iiif {
			button {
				background-image: url("@{images}/icons/icon-iiif-logo.svg");
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 16px;
			}
		}
	}
    /* ACTIONS */
    &__actions {
    	ul {
			display: flex;
			flex-wrap: wrap;
    		justify-content: center;
		}
	    li {
	    	align-self: center;
	    }
    }
    &__action {
		width: 32px;
		height: 32px;
		margin: 0 3px 6px 3px;
		border: 1px solid @gray;
	    /* ELEMENT */
	    a, 
		span, 
		button {
			display: block;
			height: 100%;
			line-height: 30px;
			text-align: center;
			color: @gray;
			cursor: pointer;
			&:hover,
			&:active,
			&:focus {
				text-decoration: none;
				color: @gray;
			}
		}
		span {
			cursor: default;
		}
		[class*="image-"] {
			position: relative;
			top: 3px;
			font-size: 24px;
		}
		&.inactive {
			opacity: 0.4;
	    	a,
	    	span,
	    	button {}
		}    
	    /* ELEMENT */
	    &.start {}
	    &.page-browse,
	    &.select {}
	    &.page-browse {
	        a, span {}
	    }
	    &.back {}
	    &.select-text {
	    	span {
		    	font-size: 14px;
	    		border: none;
		    	cursor: text;
	    	}
	    }
	    &.select {
	    	width: 96px;
	        .form-control__select {
	        	height: 30px;
	            padding: 0;
	            border: none;
	            background: none;
	            select {
	                background: none;
	            }
	        }
	    }
	    &.forward {}
	    &.end {}
	    &.double-page-view,
	    &.single-page-view,
	    &.switch-pages {
		    [class*="image-"] {
				font-size: 20px;
			}	    	
	    }
	    &.double-page-view {}
	    &.single-page-view {}
	    &.switch-pages {
	    	a {
	    		padding: 4px;
	    	}
	    	svg {
	    		fill: @gray;
	    	}
	    }
	    &.rotate-left,
	    &.rotate-right {
	    	[class*="image-"] {
				top: 4px;
			}
	    }
	    &.rotate-left {}
	    &.rotate-right {}
	    &.reset {}
	    &.fullscreen {}
	    &.zoom-slider {
	        width: 96px;
	        border: none;
	        .zoom-slider {
	            height: 16px;
	            margin-top: 8px;
	            border: 1px solid @gray;
	        }
	        .zoom-slider-handle {
	            position: relative;
	            top: -9px;
	            width: 15px;
	            min-width: inherit;
	            height: 32px;
	            line-height: inherit;
	            border: none;
	            background-color: @gray;
	        }
	    }
	}
    /* MODIFICATION */
	.labeltext {
		padding: 0;
		display: none;
	}
}
.tooltip_clipboard {
	position: absolute;
	z-index: 10000;
	background-color: rgb(68, 68, 68);
	color: #f1f2f3;
	padding: 3px;
	vertical-align: middle;
	border-radius: 10px;
	font-size: 12px;
	display: none;
	
}

/* FIREFOX CORRECTION */
@-moz-document url-prefix() {
	.image-controls__action.switch-pages .image-switchPages {
		width: 21px;
	}
} 

/* BREAKPOINT FOR LARGE DESKTOP */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {
	.image-controls {
		&__options {
			.mx-hidden;
		}
		&__option {
			width: 32px;
			height: 32px;
			a, button {
				line-height: 32px;
			}
		}
	}
}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.image-controls {
		&__actions {
			ul {
				justify-content: flex-start;
			}
		}
		&__action {
			margin: 0 5px 10px 5px;
			&.select {
				width: 74px;
			}
			&.zoom-slider {
				.mx-hidden;
			}
		}
	}
}