/* ==============================================================
   Fonts
   ==============================================================*/
/* IMAGE CONTROLS */
@font-face {
    font-family: "viewer_controls";
    src:url("@{fonts}/viewer_controls.eot?tbdisb");
    src:url("@{fonts}/viewer_controls.eot?tbdisb#iefix") format("embedded-opentype"),
        url("@{fonts}/viewer_controls.ttf?tbdisb") format("truetype"),
        url("@{fonts}/viewer_controls.woff?tbdisb") format("woff"),
        url("@{fonts}/viewer_controls.svg?tbdisb#viewer_controls") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="control-icon-"], [class*=" control-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "viewer_controls" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.control-icon-fs_back:before {
    content: "\e900";
}
.control-icon-fs_exit:before {
    content: "\e901";
}
.control-icon-fs_forward:before {
    content: "\e902";
}
.control-icon-start:before {
    content: "\e600";
}
.control-icon-back:before {
    content: "\e601";
}
.control-icon-end:before {
    content: "\e602";
}
.control-icon-forward:before {
    content: "\e603";
}
.control-icon-rotate_left:before {
    content: "\e604";
}
.control-icon-rotate_right:before {
    content: "\e605";
}
.control-icon-reset:before {
    content: "\e606";
}
.control-icon-fullscreen:before {
    content: "\e607";
}

/* NEW IMAGE CONTROLS */
@font-face {
  font-family: "image-controls";
  src:  url("@{fonts}/image-controls.eot?qi49ep");
  src:  url("@{fonts}/image-controls.eot?qi49ep#iefix") format("embedded-opentype"),
    url("@{fonts}/image-controls.ttf?qi49ep") format("truetype"),
    url("@{fonts}/image-controls.woff?qi49ep") format("woff"),
    url("@{fonts}/image-controls.svg?qi49ep#image-controls") format("svg");
  font-weight: normal;
  font-style: normal;
}

i[class^="image-"], 
i[class*=" image-"],
span[class^="image-"], 
span[class*=" image-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "image-controls" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.image-back:before {
  content: "\e900";
}
.image-forward:before {
  content: "\e901";
}
.image-start:before {
  content: "\e902";
}
.image-end:before {
  content: "\e903";
}
.image-rotate_left:before {
  content: "\e904";
}
.image-rotate_right:before {
  content: "\e905";
}
.image-reset:before {
  content: "\e906";
}
.image-fullscreen:before {
  content: "\e907";
}
.image-resultback:before {
  content: "\e908";
}
.image-resultforward:before {
  content: "\e909";
}
.image-singlePageView:before {
  content: "\e90a";
}
.image-doublePageView:before {
  content: "\e90b";
}
.image-switchPages {}
.image-switchPages span[class*="path"] {
	position: absolute;
	min-width: inherit;
	height: auto;
	margin-left: 3px;
	color: inherit;
	border: none;
	background-color: transparent;
}
.image-switchPages .path1:before {
  content: "\e90c";
}
.image-switchPages .path2:before {
  content: "\e90d";
}
.image-switchPages .path3:before {
  content: "\e90e";
}
.image-switchPages .path4:before {
  content: "\e90f";
}
.image-switchPages .path5:before {
  content: "\e910";
}
.image-switchPages .path6:before {
  content: "\e911";
}
.image-switchPages .path7:before {
  content: "\e912";
}
.image-switchPages .path8:before {
  content: "\e913";
}