/* ==============================================================
   User generated Content
   style declarations for viewImageUserGeneratedContent.xhtml
   ==============================================================*/
#view_image_user_generated_content {
    margin-bottom: 1.3125rem;
    h3 {}
    ul {
        li {
            .dl-horizontal {
                margin: 0;
                dt {
                    width: 1.875rem;
                    text-align: left;
                    font-weight: normal;
                }
                dd {
                    margin-left: 1.875rem;                    
                }
            }
        }
    }
    #user_generated_content_nav .ugc-highlight {
        background: @middlegray;
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}
