/* ==============================================================
   View Fulltext
   style declarations for viewFulltext.xhtml 
   ==============================================================*/
/* VIEW FULLTEXT */
/* BLOCK */
.view-fulltext {
	/* ELEMENT */
	&__wrapper {}
	&__box {
		pre {
			padding: 10px;
			border: 1px solid @middlegray;
			border-radius: 0;
			background-color: @lightgray;
			& > br:first-child {
				display: none;
			}
		}
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}