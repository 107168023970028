/* ==============================================================
   Widget Custom Drilldown
   style declarations for widget_customDrillDown.xhtml
   ==============================================================*/
.widget-custom-drilldown {
	.sidebar-collection-more,
	.sidebar-collection-less {
		list-style: none;
		text-align: right;
		.fa {
			margin-left: 5px;
		}
	}
}

// breakpoint for large desktops
@media screen and (max-width: @largeBreakpoint) {} 

// breakpoint for desktop
@media screen and (max-width: @mediumBreakpoint) {}

// breakpoint for tablets
@media screen and (max-width: @smallBreakpoint) {}

// breakpoint for smartphones
@media screen and (max-width: @xSmallBreakpoint) {}