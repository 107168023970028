/* ==============================================================
   Simple (Numeric) Paginator
   style declarations for simplePaginator.xhtml
   ==============================================================*/
/* NUMERIC PAGINATOR */
/* BLOCK */
.numeric-paginator {
    margin: 15px 0;
	/* ELEMENT */
	ul {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}
	li {
		min-width: 32px;
		height: 32px;
		margin: 0 3px 5px 0;
		border: 1px solid @middlegray;
	}
	a, span {
		display: block;
		line-height: 30px;
		padding: 0 3px;
		text-align: center;
	}
	a {
		text-decoration: none;
		&:hover,
		&:active,
		&:focus {
			background-color: @lightgray;
		}
	}
	span {}
	&__active {
		a, span {
			font-weight: 700;
			background-color: @middlegray;
		}
	}
	&__dots {
		a, span {}
	}
	&__navigate {}
	/* MODIFICATION */
	li.numeric-paginator__dots {
		min-width: 0;
		border: none;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.numeric-paginator {
		/* ELEMENT */
		li {
			margin-right: -1px;
		}
		a, span {
			padding: 0 4px;
		}
		/* MODIFICATION */
		li.numeric-paginator__navigate {
			width: 50%;
			order: 1;			
		}
	}
}