/* ==============================================================
   Search Standard
   style declarations for search.xhtml 
   ==============================================================*/
/* SEARCH STANDARD */
/* BLOCK */
.search-standard {
    margin-bottom: 21px;
    padding: 10px;
    border: 1px solid @middlegray;
    /* ELEMENT */
    &__help {
        font-size: 1.2rem;
        button {
            padding: 0;
            border: none;
            background: none;
        }
    }
    &__title {
        h3 {}
    }
    &__input {}
    &__input-label,
    &__filter-label {
        height: 1.75rem;
        line-height: 1.75rem;
    }
    &__input-label {}
    &__input-autocomplete {
        .ui-autocomplete {
            position: relative;
            display: block;
            .ui-autocomplete-input {
                width: 100%;
                height: 1.75rem;
                font-size: .9rem;
                border: 1px solid @middlegray;
                background-image: none;
                background-color: @white;
            }
        }
    }
    &__filter {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 7px;
        padding-bottom: 15px;
    }
    &__filter-label {}
    &__filter-radio {}
    &__filter-link {
        text-align: right;
        a {}
    }
    &__action {
        padding-top: 15px;
        text-align: right;
        .btn {
            margin: 0 0 0 5px;
        }
    }
    /* MODIFICATION */
}

/* AUTOCOMPLETE PANEL */
.ui-autocomplete-panel {
    font-size: 12px;
    border: 1px solid @lightgray;
    border-radius: 0;
    box-shadow: none;
    ul.ui-autocomplete-items {
        padding: 0;
        li.ui-autocomplete-item {
            span.ui-autocomplete-query {}
        }
        li.ui-state-highlight {
            color: @black;
            border: none;
            border-radius: 0;
            background: @lightgray;
        }
    }
}

/* SEARCH HELP MODAL */
/* BLOCK */
.search-help {
    /* ELEMENT */
    .modal-dialog {}
    .modal-content {}
    .modal-header {}
    .modal-title {}
    .modal-body {}
    .modal-footer {}
    /* MODIFICATION */
}

/* SEARCH TILE */
/* BLOCK */
.search-tile {
    margin-bottom: 21px;
    padding: 10px;
    border: 1px solid @middlegray;
    /* ELEMENT */
    &__description {
        min-height: 125px;
        h3 {
            display: inline-block;
        }
    }
    &__icon {
        float: right;
        margin: 0 0 10px 10px;
        font-size: 3rem;
    }
    &__input {}
    &__action {
        padding-top: 15px;
        text-align: right;
        .btn {
            margin: 0;
        }
    }
    /* MODIFICATION */
}
        
/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}