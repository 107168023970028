/* ==============================================================
   Simple Lightbox
   style declarations for Simple Lightbox JS
   ==============================================================*/
.lightbox-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 9999;
}

.lightbox-modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40%;
    padding: 1em;
    text-align: left;
    background-color: #FFFFFF;
}

.lightbox-close {
    position: relative;
}

.lightbox-close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}

.lightbox-modal-box img {
    display: block;
    width: 100%;
    margin: 1%;
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}