/* ==============================================================
   CSS Template Hacks for different browsers
   ==============================================================*/
/* INTERNET EXPLORER > 10 AND EDGE */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	// do something...
}

/* GOOGLE CHROME */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	// do something...
}

/* FIREFOX */
@-moz-document url-prefix() {
	// do something...	
} 