/* ==============================================================
   Tag Cloud
   style declarations for tagCloud.xhtml
   ==============================================================*/
#tagcloudWrapper{
	margin-top: 20px;
	margin-bottom:15px;
	margin-left:20px;
}

.tagcloudElement {
	display:inline-block;
	text-align:center;
	margin:2px;
}

/* IE7 fix */
* + html .tagcloudElement {
    display: inline;
}


#tagcloud {
	text-align:center;
}
#tagcloud a {
	text-align:center;
	text-decoration: none;
}
#tagcloud a:hover {
	text-align:center;
	text-decoration: underline;
}

.tag1 a,
.tag2 a,
.tag3 a,
.tag4 a,
.tag5 a,
.tag6 a,
.tag7 a,
.tag8 a,
.tag9 a,
.tag10 a{
	font-size: 100%;
	color:  #696969 !important;
	/*border-bottom: 1px dotted #DFCFB5 !important;*/
}

.tag11 a,
.tag12 a,
.tag13 a,
.tag14 a,
.tag15 a,
.tag16 a,
.tag17 a,
.tag18 a,
.tag19 a,
.tag20 a {
	font-size: 110%;
	color: #696969 !important;
	/*border-bottom: 1px dotted  #CFC0A8 !important;*/
}

.tag21 a,
.tag22 a,
.tag23 a,
.tag24 a,
.tag25 a,
.tag26 a,
.tag27 a,
.tag28 a,
.tag29 a,
.tag30 a {
	font-size: 120%;
	color:#696969 !important;
	/*border-bottom: 1px dotted #BFB19B !important;*/
}

.tag31 a,
.tag32 a,
.tag33 a,
.tag34 a,
.tag35 a,
.tag36 a,
.tag37 a,
.tag38 a,
.tag39 a,
.tag40 a{
	font-size: 130%;
	color: #696969 !important;
	/*border-bottom: 1px dotted  #AF9E83 !important;*/
}

.tag41 a,
.tag42 a,
.tag43 a,
.tag44 a,
.tag45 a,
.tag46 a,
.tag47 a,
.tag48 a,
.tag49 a,
.tag50 a {
	font-size: 140%;
	color:#696969 !important;
	/*border-bottom: 1px dotted #9F8C6D  !important;*/
}

.tag51 a,
.tag52 a,
.tag53 a,
.tag54 a,
.tag55 a,
.tag56 a,
.tag57 a,
.tag58 a,
.tag59 a,
.tag60 a {
	font-size: 150%;
	color:#696969 !important;
	/*border-bottom: 1px dotted #8F7E62 !important;*/
}

.tag61 a,
.tag62 a,
.tag63 a,
.tag64 a,
.tag65 a,
.tag66 a,
.tag67 a,
.tag68 a,
.tag69 a,
.tag70 a {
	font-size: 160%;
	color: #696969 !important;
	/*border-bottom: 1px dotted #7F6C4F !important;*/
}

.tag71 a,
.tag72 a,
.tag73 a,
.tag74 a,
.tag75 a,
.tag76 a,
.tag77 a,
.tag78 a,
.tag79 a,
.tag80 a {
	font-size: 170%;
	color:  #696969 !important;
	/*border-bottom: 1px dotted #6F5C3E !important;*/
}
.tag81 a,
.tag82 a,
.tag83 a,
.tag84 a,
.tag85 a,
.tag86 a,
.tag87 a,
.tag88 a,
.tag89 a,
.tag90 a{
	font-size: 170%;
	color: #696969  !important;
	/*border-bottom: 1px dotted #5F4C2F  !important;*/
}
.tag91 a,
.tag92 a,
.tag93 a,
.tag94 a,
.tag95 a,
.tag96 a,
.tag97 a,
.tag98 a,
.tag99 a,
.tag100 a{
	font-size: 170%;
	color:  #696969 !important;
	/*border-bottom: 1px dotted #4F3E22 !important;*/
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}