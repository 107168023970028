/* ==============================================================
   Widget Work Count
   style declarations for widget_workCount.xhtml
   ==============================================================*/
/* WIDGET WORKCOUNT */
/* BLOCK */
#widgetWorkCount {
	p {
		margin: 0;
		padding: 5px 10px;
	}	
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}