/* ==============================================================
   Mobile Navigation
   style declarations for mobileNavigation.xhtml
   ==============================================================*/
/* MOBILE NAVIGATION */
/* BLOCK */
.mobile-navigation {
    display: none;
    position: fixed;
    top: 52px;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 250px;
    padding: 0 15px;
    border-top: 1px solid @lightgray;
    border-bottom: 1px solid @lightgray;
    background-color: @white;
    overflow-y: auto;
    z-index: 999;
    /* ELEMENT */
    ul {}
    li {}
	a {
		display: block;
		padding: 10px;
		&.mainnav-active {}
	}
	/* USER LOGIN */
	&__user-login {}
	/* SUBNAV */
    .mainsubnav {
        padding-left: 15px;
        h3 {}
        li {}
        a {}
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}