/* ==============================================================
   Search Timeline
   style declarations for searchTimeline.xhtml 
   ==============================================================*/
/* BLOCK */
.search-timeline {
    /* ELEMENT */
    .btn {
        margin: 0 0 0 5px;
    }
    &__title {
        h2 {}
    }
    &__toggle {
        &.btn {
            margin: 0 0 21px 0;
        }
        &:active,
        &:focus {
            outline: none;
        }
    }
    &__input {
        padding: 21px;
        border-top: 1px solid @middlegray;
        background-color: @lightgray;
        input {}
        .form-horizontal {}
        .form-group {}
        .col-sm-4 {}
        .col-sm-8 {}
        .control-label {}
        .form-control {}
    }
    &__select-wrapper {
        height: 34px;
        padding: 6px;
        font-size: 14px;
        line-height: 1.42857143;
        background-color: @white;
        background-image: none;
        border: 1px solid @middlegray;
        select {
            padding: 0 6px;
            border: none;
            box-shadow: none;
        }
    }
    &__reset {
        text-align: right;
        .btn {}
    }
    &__result {
        margin-bottom: 21px;
        padding: 21px 0;
        border-top: 1px solid @middlegray;
        border-bottom: 1px solid @middlegray;
    }
    &__years,
    &__months,
    &__days {
		.mx-clearfloats;
        a,
        span {
            display: block;
            padding: 5px 10px;
            text-align: center;
    		text-overflow: ellipsis;
            overflow: hidden;
        }        
    }
    &__year,
    &__month,
    &__day {
    	display: inline-block;
    	float: left;
    }
    &__years {
    }
    &__year {
    	width: calc(100% / 10);
        a {}
    }
    &__months {
        border-top: 1px solid @middlegray;
        border-bottom: 1px solid @middlegray;
        background-color: @lightgray;
    }
    &__month {
    	width: calc(100% / 12);
        a {}
    }
    &__days {
        margin-bottom: 10px;
        border-bottom: 1px solid @middlegray;
    }
    &__day {
        width: calc(100% / 31);
        a,
        span {
            padding: 0;
        }
        a {}
        span {}
    }
    &__action {
        a {}
        .btn {}
    }
    /* MODIFICATION */
    &__year {
        &.active {
            background-color: @middlegray;
        }                
    }
    &__month {
        &.active {
            font-weight: bold;
            text-decoration: underline;
        }                
    }
    &__day {
        &.active {
            background-color: @middlegray;
        }                
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {
	.search-timeline__years a, 
	.search-timeline__months a, 
	.search-timeline__days a, 
	.search-timeline__years span, 
	.search-timeline__months span, 
	.search-timeline__days span {
		padding: 5px;
	}
	.search-timeline__month {
	    width: calc(100% / 6);
	}
	.search-timeline__day {
	    width: calc(100% / 15.5);
	}
}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.search-timeline__year {
	    width: calc(100% / 5);
	}
	.search-timeline__month {
	    width: calc(100% / 3);
	}
	.search-timeline__day {
	    width: calc(100% / 7.75);
	}
}