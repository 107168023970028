/* ==============================================================
   Widget SearchInCurrentItem
   style declarations for widget_searchInCurrentItem.xhtml
   ==============================================================*/
/* WIDGET SEARCH IN CURRENT ITEM */
/* BLOCK */
.widget-search-in-current-item {
	/* ELEMENT */
    .form-group {
        width: 100%;
        margin: 0;
    }
    .input-group {
    	width: 100%;
    	input {}
    }
    .input-group-addon {
    	padding: 0;
    	border-radius: 0;
    	input {
    		width: 30px;
    		height: 30px;
    		margin: 0;
    		padding: 0;
    		border: none;
    		background-image: url("@{images}/icons/icon-search.svg");
    		background-repeat: no-repeat;
    		background-position: center center;
    		background-size: 20px;
    		background-color: transparent;
    	}
    }
}

/* CHROME POSITIONING HACK */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
	.widget-search-in-current-item {
		.input-group-addon {
	    	input {
	    		position: relative;
	    		top: 1px;
	    	}
	    }
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}