/* ==============================================================
   Print CSS
   style declarations for print views
   ==============================================================*/
@media print {
    * {
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        filter: none !important;
        -ms-filter: none !important;
    }
    a, a:visited {
        color: #444 !important;
        text-decoration: underline;
    }
    a:link:after {
        content: "";
    }
    abbr[title]:after {
        content: "";
    }
    pre, blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr, img {
        page-break-inside: avoid;
    }
    @page {
        margin: 0.5cm;
    }
    p, h2, h3 {
        orphans: 3;
        widows: 3;
    }
    h2, h3 {
        page-break-after: avoid;
    }
}