/* ==============================================================
   Widget Toc
   style declarations for widget_toc.xhtml
   ==============================================================*/
#widgetToc {
    position: relative;
    #AJAXLoaderSidebarToc {
        background-image: url("@{images}/ajax_preloader.gif");
        background-position: center center;
        background-repeat: no-repeat;
    }
    .widget-toc-title {
        position: relative;
        h3 {}
        .widget-toc-title-expand {
            position: absolute;
            top: 0;
            right: 0;
            border: none;
            li {
                display: inline-block;
                float: left;
                margin-left: 7px;
                padding: 0;
                border: inherit;
                a {
                	border: inherit;
                	.fa-stack {
	                	font-size: 10px;
	                	line-height: 18px;
                	}
                }
            }
        }
    }
    .widget-toc-elem-wrapp {
        max-height: 25rem;
        overflow: auto;
        ul {
            li {
                &.parent {
                    .widget-toc-elem-link {
                    	a {}
                    }
                }
                &.last-child {              
                    .widget-toc-elem-link {}
                }
                .widget-toc-elem-expand {
                    float: left;
                    width: 16px;
                    min-height: 1px;
                    a {
                    	display: block;
                    	padding: 3px 0;
                    }
                }
                .widget-toc-elem-link {
                    float: left;
                    width: calc(~"100% - 16px");
                    a {
                    	display: block;
                    	padding: 3px 5px 3px 15px;
	                    &:hover,
	                    &:focus,
	                    &:active {
	                        text-decoration: none;
	                    }
	                    &:hover {
	                        background-color: @lightgray;
	                    }
                        &.active {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}