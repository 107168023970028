/* ==============================================================
   CSS Helper Classes
   ==============================================================*/
/* HORIZONTAL LIST */
/* BLOCK */
.list {
	margin: 0;
	padding: 0;
	list-style: none;
	.mx-clearfloats;
	/* ELEMENT */
	li {
		position: relative;
	}
	a, span {
		display: block;
	}
	/* MODIFICATION */
	&--horizontal {
		li {
			display: inline-block;
			float: left;
		}
		a, span {
			padding: 6px 12px;
		}
	}
	&--center {
		position: relative;
        float: left;
        left: 50%;
        li {
            right: 50%;
        }
	}
	&--divider {
		li:after {
			content: "\007c";
			position: absolute;
			display: block;
			top: 5px;
    		right: -4px;
		}
		li:last-child:after {
			display: none;
		}
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {
	.list--horizontal li {
	    display: block;
	    float: none;
	    text-align: center;
	}
}

/* IMAGES */
.faded {
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.25);
	}
}
.broken {
	position: relative;
	display: inline-block;
	width: 100%;
	height: auto;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	&:before {  
	  content: " ";
	  display: block;
	  position: absolute;
	  top: -10px;
	  left: 0;
	  width: 100%;
	  height: calc(~"100% + 10px");
	  background-color: @middlegray;
	}
	&:after {  
	  content: "\f127" " " attr(alt);
	  position: absolute;
	  display: block;
	  top: 5px;
	  left: 0;
	  width: 100%;
	  font-size: 14px;
	  font-style: normal;
	  font-family: FontAwesome;
	  text-align: center;
	  color: rgb(100, 100, 100);
	}
}
/* COORDS HIGHLIGHTING */
.coords-highlighting {
	background-color: yellow;
	opacity: 0.5;
	&.focus {}
}
/* TYPO */
.uppercase {
	text-transform: uppercase;
}
/* FIREFOX LAYOUT FIXES */
.oneUp {
	position: relative;
	top: -1px;
}
/* VISUALLY HIDDEN */
.visually-hidden {
    position: absolute; 
    width: 1px;
    height: 1px; 
	clip: rect(1px, 1px, 1px, 1px); 
    white-space: nowrap; 
    overflow: hidden; 
}
/* TABLE RESET */
.table-reset {
	width: auto;
	thead, tbody, tfoot, tr, th, td {
		display: block;
		width: auto;
		height: auto;
		margin: 0;
		padding: 0;
		border: none;
		border-collapse: inherit;
		border-spacing: 0;
		border-color: inherit;
		vertical-align: inherit;
		text-align: left;
		font-weight: inherit;
		-webkit-border-horizontal-spacing: 0;
		-webkit-border-vertical-spacing: 0;
	}
	th, td {
		display: inline;
	}
}