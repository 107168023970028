/* ==============================================================
   ePub
   style declarations for ePub.xhtml
   ==============================================================*/
/* EPUB */
/* BLOCK */
.e-pub {
	margin-bottom: 21px;
	/* ELEMENT */
	h3 {}
	p {}
	&__items {
		.mx-grid(@width: 75px; @gap: 10px);
	}
	&__item {
		position: relative;
		padding: 5px;
		text-align: center;
		border: 1px solid @middlegray;
		background-color: @white;
		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-width: 6px;
			border-style: solid;
			border-color: transparent;
		}
		&:before {
		    top: 1px;
		    left: 1px;
			border-bottom-color: @middlegray;
			transform: rotate(135deg);
		}
		&:after {
			top: -7px;
		    left: -7px;
			border-bottom-color: @white;
			transform: rotate(-45deg);
		}
	}
	&__item-order-label {
		display: block;
		margin-top: 3px;
		padding: 1px 3px;
		color: @white;
		font-size: 0.75rem;
		border-radius: 5px;
		background-color: @blue;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}