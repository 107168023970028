/* ==============================================================
   Widget Chronology
   style declarations for widget_chronology.xhtml
   ==============================================================*/
/* WIDGET CHRONOLOGY */
/* BLOCK */
.widget-chronology-slider {
	display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 1.3125rem;
    padding: 0 10px;
    font-size: 1.125rem;
	color: @black;
	/* ELEMENT */
	&__item {
		&.chronology-slider-label,
		&.chronology-slider-actions {
    		align-self: center;
    		padding-bottom: 10px;
		}
		&.chronology-slider-actions {
			ul {
				.mx-list-reset;
				display: flex;
				justify-content: flex-end;
			}
			li {
				text-align: right;
			}
			img {
				width: 14px;
				height: 14px;
				margin-top: -2px;
			}
			[data-reset="chrono-slider"] {
				padding-left: 5px;
			}
		}
		&.chronology-slider-action-loader,
		&.chronology-slider-action-reset {
			display: none;
			&.active {
				display: list-item;
			}
		}
		&.chronology-slider-action-loader {}
		&.chronology-slider-action-reset {}
		&.chronology-slider-start,
		&.chronology-slider-end {
			font-size: 0.875rem; 
		}
		&.chronology-slider-start {}
		&.chronology-slider-end {
			text-align: right;
		}
		&.chronology-slider {
			grid-column: span 2;
			padding: 10px 0;
		}
	}
}

#chronoSlider {
	height: 10px;
	border-radius: 5px;
	border-color: @lightgray;
	background-image: none;
	background-color: @lightgray;
	.ui-slider-range,
	.ui-slider-handle {
	    border-radius: 4px;
		background-image: none;
        &:focus {
        	outline: none;
        }
	}
	.ui-slider-range {
	    border-color: @blue;
		background-color: @blue;		
	}
	.ui-slider-handle {
		width: 10px;
	    height: 8px;
	    top: 0;
	    margin: 0;
	    border-color: @gray;
	    background-color: @middlegray;
	    cursor: pointer;
	}
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}