/* ==============================================================
   View NER Facetting
   style declarations for viewNerFacetting.xhtml
   ==============================================================*/
#nerFacetting {
    float: left;
    width: 100%;
    h3 {}
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .facetting-content {
        .nav-tabs {
            li {
                a {
                    .fa {}
                }
            }
        }
        .tab-content {
            position: relative;
            .tab-pane {
                position: relative;
                padding: 10px;
                &.active {}
                h4 {}                
            }
        }
    }
}

/* LOADER */
#nerFacettingLoader {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: @white;
    background-image: url("@{images}/ajax_preloader.gif");
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 999;
}

/* OVERVIEW & SECTION */
#nerFacetting #overview,
#nerFacetting #section {
    position: relative;
    border: 1px solid #ddd;
    border-top: none;
    .tag-filter-description {
        float: left;
        width: 100%;
        margin-bottom: 5px;
        font-size: 1rem;
    }
    .set-tag-range-view {
        width: 25%;
    }
    .filter-tags {
        margin: 0 0 15px 0;
        padding: 0;
        list-style: none;
        li {
            display: inline-block;
            float: left;
            font-size: 1rem;
            margin-right: 6px;
            &:last-child {
                margin: 0;
            }
            a {
                text-decoration: none;
                color: @middlegray;
                &:hover {
                    text-decoration: none;
                    color: @middlegray;
                }
                &.active[data-type="person"] {
                    color: @personColor;
                }
                &.active[data-type="location"] {
                    color: @locationColor;
                }
                &.active[data-type="corporation"] {
                    color: @corporationColor;
                }
            }
            &.reset-filter {
                display: none;
            }
        }
    }
}

/* OVERVIEW */
#nerFacetting #overview {
    .current-tags {
        position: absolute;
        display: none;
        left: 12%;
        min-width: 25px;
        max-width: 100%;
        padding: 2px 4px;
        font-size: 0.8rem;
        color: @black;
        border: 1px solid @gray;
        border-radius: 4px;
        background-color: @white;
        box-shadow: 0px 5px 7px rgba(0,0,0,0.4);
        z-index: 999;
        &:before {
            content: "";
            position: absolute;
            display: block;
            top: -5px;
            left: 10px;
            width: 0;
            height: 0;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent @gray transparent;
        }
        .page-tag {
            float: left;
            display: inline-block;
            margin: 0 3px 3px 0;
            &:hover {
                background-color: @tagHoverColor;                
            }
            &.empty {}
            &.person {
                color: @personColor;
            }
            &.location {
                color: @locationColor;
            }
            &.corporation {
                color: @corporationColor;
            }
        }
    }
    .overview-content {
        max-height: inherit;
        margin-top: 15px;
        overflow-x: hidden;
        overflow-y: visible;
        .overview-scale {
            li {
                float: left;
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                div {
                    float: left;
                }
                .page-number {
                    width: 12%;
                    font-size: 1rem;
                    font-weight: bold;
                    a {
                        color: @black;
                        &:hover,
                        &:active,
                        &:focus {
                            text-decoration: none;
                            color: @black;
                        }
                    }
                }
                .tag-container {
                    width: 88%;
                    font-size: 1rem;
                    .page-tag {
                        margin-right: 3px;
                        white-space: nowrap;
                        &:hover {
                            background-color: @tagHoverColor;
                        }
                        &.empty {}
                        &.person {
                            color: @personColor;
                        }
                        &.location {
                            color: @locationColor;
                        }
                        &.corporation {
                            color: @corporationColor;
                        }
                    }
                }
            }
        }
    }
}

/* SECTION */
#nerFacetting #section {}

#nerFacetting #sliderScale {
    position: relative;
    float: left;
    width: 10%;
    margin-top: 15px;
    border-top: 1px solid @middlegray;
    border-bottom: 1px solid @middlegray;
    .scale-page {
        position: absolute;
        left: -8px;
        font-size: 0.9rem;
        color: @middlegray;
        &.start {
            top: 0;
        }
        &.end {
            bottom: 0;
        }
    }
    .slider-description {
        position: absolute;
        top: 50%;
        left: -52px;
        width: 100px;
        margin-top: -10px;
        font-size: 1rem;
        text-align: center;
        color: @middlegray;
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
    .scale-stripes {
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        z-index: 9;
        li {
            clear: both;
            float: right;
            width: 50%;
            height: 60px;
            border-bottom: 1px solid @middlegray;
            &.middle {
                width: 75%;
            }
            &:last-child {
                border: none;
            }
        }
    }
    .scale-section-stripe {
        position: absolute;
        display: block;
        top: 0;
        right: 3px;
        width: 2px;
        height: 100px;
        background-color: @scaleHandleColor;
        z-index: 11;
    }
}

#nerFacetting #sliderVertical {
    float: right;
    width: 2px;
    height: 600px;
    margin-right: 3px;
    border: none;
    background: @middlegray;
    z-index: 10;
    .ui-slider-handle {
        left: -25px;
        width: 10px;
        height: 16px;
        margin-bottom: -8px;
        border: none;
        background: @scaleHandleColor;
        &:after {
            content: "";
            position: absolute;
            display: block;
            top: -7px;
            right: -30px;
            width: 0;
            height: 0;
            border-width: 15px;
            border-style: solid;
            border-color: transparent transparent transparent @scaleHandleColor;
        }
        &:focus {
            outline: none;
        }
        .page-bubble {
            position: absolute;
            top: -3px;
            left: 2.6em;
            padding: 2px 4px;
            font-size: 1rem;
            color: @white;
            border-radius: 4px;
            background-color: @black;
            white-space: nowrap;
            z-index: 999;
            &:before {
                content: "";
                position: absolute;
                display: block;
                top: 50%;
                left: -10px;
                width: 0;
                height: 0;
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent @black transparent transparent;
            }
        }
    }
}

#nerFacetting #sectionTags {
    float: left;
    width: 90%;
    max-height: inherit;
    margin-top: 15px;
    padding-left: 3px;
    text-align: center;
    overflow-y: visible;
    .page-tag {
        display: inline-block;
        margin-right: 3px;
        &:hover {
            background-color: @tagHoverColor;
        }
        &.empty {
            display: none;
        }
        &.person {
            color: @personColor;
        }
        &.location {
            color: @locationColor;
        }
        &.corporation {
            color: @corporationColor;
        }
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {} 

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}