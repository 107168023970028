/* ==============================================================
   CMS Template "Searchlist"
   style declarations for cms_template_16_searchList.xhtml
   ==============================================================*/
/* SEARCHLIST */
/* BLOCK */
.tpl-search-list {
    /* ELEMENT */
    &__intro {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid @lightgray;
    }
}

/* BREAKPOINT FOR LARGE DESKTOPS */
@media screen and (max-width: @largeBreakpoint) {}

/* BREAKPOINT FOR DESKTOP */
@media screen and (max-width: @mediumBreakpoint) {}

/* BREAKPOINT FOR TABLETS */
@media screen and (max-width: @smallBreakpoint) {}

/* BREAKPOINT FOR SMARTPHONES */
@media screen and (max-width: @xSmallBreakpoint) {}